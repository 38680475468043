import {
    Box,
    Drawer,
    DrawerContent,
    useDisclosure,
    Flex,
    Text,
  } from "@chakra-ui/react";
  import MobileNav from "../../sidebar/MobileNav.tsx";
  import SideNav from "../../sidebar/SideBar.tsx";
  import { ReactNode, useEffect, useMemo, useState } from "react";
  import { TbInfoTriangle } from "react-icons/tb";
  import { RxCross2 } from "react-icons/rx";
  import axios from "axios";
import React from "react";
  //import { PUBLIC_URL } from "../common/utils";
  //import { prepareAnnouncements } from "../common/prepare-data";
  
  interface DashboardProps {
    title?: ReactNode;
    children?: ReactNode;
  }
  
  const SDashboard = (props: DashboardProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { children } = props;
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);
    const [announcements, setAnnouncements] = useState([]);
  
    // useEffect(() => {
    // //   const loggedin = localStorage.getItem("isActive") === "ACTIVE";
    // //   setIsLoggedIn(loggedin);
    // }, [localStorage.getItem("isActive")]);
  
    return isLoggedIn ? (
      <Box minH="100vh" bg={"white"}>
        <SideNav
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SideNav onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: "16rem" }} p="4">
          {children}
        </Box>
      </Box>
    ) : (
      <Flex w={"100%"}>{children}</Flex>
    );
  };
  
  export default SDashboard;
  