import React, { useState, useEffect } from 'react';
import {
  Box, Input, Divider, VStack, Flex, Avatar as ChakraAvatar, Text,
} from '@chakra-ui/react';
import avatar from '../../../Assets/userImage.png';
import logounichat from "../../../Assets/UnichatLogo.png";

function ConvoList({ users, onUserClick, groupName, selectedUser}) {
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const userM = JSON.parse(sessionStorage.getItem('userMap') || '{}');

  useEffect(() => {
    setCurrentSelectedUser(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    setFilteredUsers(
      users.filter(user =>
        user.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  };

  const handleUserClick = (user) => {
    setCurrentSelectedUser(user);
    onUserClick(user);
  };

  return (
    <Box w="27%" h="100%" p={4} borderRadius="xl" boxShadow="0 0 10px rgba(0, 0, 0, 0.1)" bg="white" marginRight={3}>
      <Input
        placeholder="Search users..."
        value={searchTerm}
        onChange={handleSearch}
        mb={4}
        height="54px"
      />
      <Divider borderWidth="1.5px" mb={4} />
      <VStack
        spacing={4}
        align="stretch"
        h="calc(100% - 116px)"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',  // IE and Edge
          'scrollbar-width': 'none',  // Firefox
        }}
      >
        <Flex
          align="center"
          p={2}
          borderRadius="md"
          bg={currentSelectedUser === null ? "purple.900" : "white"}
          color={currentSelectedUser === null ? "white" : "purple.900"}
          _hover={{ bg: "purple.900", color: "white" }}
          cursor="pointer"
          onClick={() => handleUserClick(null)}
          justifyContent="space-between" // Ensure space between the two columns
        >
          <Flex align="center"> {/* Column for text */}
            <ChakraAvatar size="lg" src={logounichat} name="Group Chat" />
            <Box ml={3}>
              <Text fontWeight="bold">Group Chat</Text>
              <Text fontSize="sm">{groupName}</Text>
            </Box>
          </Flex>

          {userM['groupChat']?.unreadCount > 0 && currentSelectedUser !== null &&( // Column for unread count
            <Box
              bg="purple.700"
              color="white"
              borderRadius="full"
              width="24px"
              height="24px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="xs"
              fontWeight="bold"
            >
              {userM['groupChat']?.unreadCount || 0}
            </Box>
          )}
        </Flex>
        <Divider />
        {filteredUsers.map((user, index) => (
          <>
            <Flex
              key={index}
              align="center"
              p={2}
              borderRadius="md"
              bg={currentSelectedUser?._id === user._id ? "purple.900" : "white"}
              color={currentSelectedUser?._id === user._id ? "white" : "purple.900"}
              _hover={{ bg: "purple.900", color: "white" }}
              cursor="pointer"
              onClick={() => handleUserClick(user)}
              justifyContent="space-between" // Ensure space between the two columns
            >
              <Flex align="center"> {/* Column for text */}
                <ChakraAvatar size="lg" src={user.photoUrl || user.photoURL || avatar} />
                <Box ml={3}>
                  <Text fontWeight="bold">{user.name}</Text>
                  <Text fontSize="sm">{user.email}</Text>
                </Box>
              </Flex>

              {userM[user._id]?.unreadCount > 0 && currentSelectedUser?._id!==user._id && ( // Column for unread count
                <Box
                  bg="purple.700"
                  color="white"
                  borderRadius="full"
                  width="24px"
                  height="24px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="xs"
                  fontWeight="bold"
                >
                  {userM[user._id]?.unreadCount || 0}
                </Box>
              )}
            </Flex>
            <Divider />
          </>
        ))}
      </VStack>
    </Box>
  );
}

export default ConvoList;
