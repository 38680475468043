"use client";
import React from 'react';
import { Box, Text, VStack, Divider } from '@chakra-ui/react';

const TimeLine = () => {
    return (
        <Box
            position="fixed"
            top="80px"
            left="0"
            zIndex="1000"
            height="100vh"
            width="fit-content"
            p="4"
            bg="transparent"
        >
            <VStack
                spacing={4}
                align="flex-start"
                color="white"
                divider={
                    <Divider
                        borderColor="primaryButtonColor"
                        borderWidth="2px"
                        orientation="vertical"
                        height="100%"
                    />
                }
            >
                
            </VStack>
        </Box>
    );
}

export default TimeLine;
