import React, { useState, useEffect } from 'react';
import {
  Box, Flex, VStack, HStack, Avatar as ChakraAvatar, Text, Input, IconButton, Spinner, useToast, Textarea, Tooltip,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { getTeachersByIds, getStudentsByIds, getProjectsByIds, AddMessage } from "../../../Services/Teacher/teacherService.tsx";
import { ArrowForwardIcon } from '@chakra-ui/icons';
import ConvoList from './ConvoList.tsx';
import logounichat from "../../../Assets/UnichatLogo.png";
import avatar from '../../../Assets/userImage.png';
import { pusherClient } from '../../../pusherConfig.js';
import notificationSound from '../../../Assets/notification.mp3';

function Chat({ inputFromChat, clearPrefillMessage }) {
  const { projectId } = useParams();
  const [project, setProject] = useState({});
  const [users, setUsers] = useState([]);
  const [userMap, setUserMap] = useState(JSON.parse(sessionStorage.getItem('userMap')) || {});
  const [messageInputValue, setMessageInputValue] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [messageData, setMessageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIsGroupChat, setCurrentIsGroupChat] = useState(true);
  const currentuser = sessionStorage.getItem("CurrentLoggedInTId")?.trim();
  const toast = useToast();

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const data = await getProjectsByIds([projectId]);
        if (data.length > 0) {
          setProject(data[0]);
          setMessageData(data[0].messages);
        } else {
          throw new Error("No project data found.");
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "Error fetching project details.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [projectId, toast]);

  useEffect(() => {
    if (!project.studentIds || !project.teacherIds) return;

  const fetchUserData = async () => {
    try {
      const students = await getStudentsByIds(project.studentIds);
      const teachers = await getTeachersByIds(project.teacherIds);
      const allUsers = [...students, ...teachers];
  
      // Load previous user map from sessionStorage
      const previousUserMap = JSON.parse(sessionStorage.getItem('userMap')) || {};
      
      // Create a new user map from fetched data
      const newUserMap = {};
      allUsers.forEach(user => {
        
        newUserMap[user._id] = { 
          ...user, 
          unreadCount: previousUserMap[user._id]?.unreadCount || 0 
        };
      });
  
      
      const previousGroupChat = previousUserMap['groupChat'] || { unreadCount: 0 };
      const newGroupChat = {
        ...previousGroupChat,
        unreadCount: previousGroupChat.unreadCount, 
      };
  
      
      setUsers(allUsers);
      setUserMap({ ...newUserMap, groupChat: newGroupChat });
      
      
      sessionStorage.setItem('userMap', JSON.stringify({ ...newUserMap, groupChat: newGroupChat }));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  
  fetchUserData();
}, [project]);
  
  
useEffect(() => {
  if (inputFromChat) {
    if(!inputFromChat.query){
      const formattedMessage = `**Message from ChatGPT**\n\nMessage: ${inputFromChat || ''}`;
    setMessageInputValue(formattedMessage.trimEnd());
    }
    else{
    const formattedMessage = `**Message from ChatGPT**\n\nQuery: ${inputFromChat.query || ''}\n\nResponse: ${inputFromChat.response || ''}\n\nSent by: ${userMap[inputFromChat.userId]?.name || inputFromChat.userId}\n`;
    setMessageInputValue(formattedMessage.trimEnd()); 
    }
  }
}, [inputFromChat, userMap]);

  useEffect(() => {
    if (!project._id) return;

    const projectChannel = pusherClient.subscribe(project._id);
    const peerChannel = pusherClient.subscribe(`peerchat-${project._id}`);

    const handleNewMessages = (data) => {
      let newM = [data.messages];
      let allMessages;
      if (!data.isGroupChat) {
        allMessages = [...(project.individualmessages || []), ...newM];
        if (!currentIsGroupChat && selectedUser) {
          const filteredMessages = allMessages.filter(
            ele => (ele.sender === currentuser && ele.receiver === selectedUser._id) ||
                   (ele.sender === selectedUser._id && ele.receiver === currentuser)
          );
          setMessageData(filteredMessages);
        }
        setProject((prevDetails) => ({
          ...prevDetails,
          individualmessages: allMessages,
        }));
      } else {
        allMessages = [...(project.messages || []), ...newM];
        if(currentIsGroupChat){
          setMessageData(allMessages);
        }
        setProject((prevDetails) => ({
          ...prevDetails,
          messages: allMessages,
        }));
      }

      const lastMessage = data.messages;
      if (lastMessage && lastMessage.sender !== currentuser) {
        const audio = new Audio(notificationSound);
        if (document.interacted) {
          const audio = new Audio(notificationSound);
          audio.play();
        } else {
          document.addEventListener('click', () => {
            const audio = new Audio(notificationSound);
            audio.play();
            document.interacted = true;
          }, { once: true });
        }

        const updatedUserMap = { ...userMap };

        if (!lastMessage.isPeerMessage) {
          updatedUserMap["groupChat"] = {
            ...updatedUserMap["groupChat"],
            unreadCount: (updatedUserMap["groupChat"]?.unreadCount || 0) + 1,
          };
        } else {
          const senderId = lastMessage.sender;
          if (updatedUserMap[senderId]) {
            updatedUserMap[senderId] = {
              ...updatedUserMap[senderId],
              unreadCount: (updatedUserMap[senderId].unreadCount || 0) + 1,
            };
          }
        }

        setUserMap(updatedUserMap);
        console.log(updatedUserMap)
        sessionStorage.setItem('userMap', JSON.stringify(updatedUserMap)); // Update sessionStorage

        toast({
          title: "New Message",
          description: `You have a new message from ${userMap[lastMessage.sender]?.name || 'unknown'}.`,
          status: "info",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    projectChannel.bind('messages:new', handleNewMessages);
    peerChannel.bind('individualmessages:new', handleNewMessages);

    return () => {
      projectChannel.unbind('messages:new', handleNewMessages);
      pusherClient.unsubscribe(project._id);
      peerChannel.unbind('individualmessages:new', handleNewMessages);
      pusherClient.unsubscribe(`peerchat-${project._id}`);
    };
  }, [project._id, currentuser, userMap, toast]);

  const onSend = async () => {
    const newMessage = {
      message: messageInputValue,
      sender: currentuser,
    };
    setMessageInputValue("");

    try {
      if(messageInputValue.length > 0) {
        if (selectedUser) {
          await AddMessage({ id: project._id, senderId: currentuser, receiverId: selectedUser._id }, newMessage);
        } else {
          await AddMessage({ id: project._id }, newMessage);
        }
        clearPrefillMessage();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Error sending message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUserClick = (user) => {
    const updatedUserMap = { ...userMap };
    if (user) {
      updatedUserMap[user._id] = {
        ...updatedUserMap[user._id],
        unreadCount: 0,
      };
    } else {
      if (!updatedUserMap["groupChat"]) {
        updatedUserMap["groupChat"] = { unreadCount: 0 };
      } else {
        updatedUserMap["groupChat"].unreadCount = 0;
      }
    }
    setUserMap(updatedUserMap);
    sessionStorage.setItem('userMap', JSON.stringify(updatedUserMap)); // Update sessionStorage

    setSelectedUser(user);
    setCurrentIsGroupChat(user === null);

    if (user) {
      const filteredMessages = project?.individualmessages?.filter(
        (ele) =>
          (ele.sender === currentuser && ele.receiver === user._id) ||
          (ele.sender === user._id && ele.receiver === currentuser)
      );
      setMessageData(filteredMessages);
    } else {
      setMessageData(project?.messages);
    }
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box w="100%" h="75vh" mt={4} p={4} borderRadius="xl" bg="white">
      <Flex h="110%">
        {project.nativeChat === false ? (
          <iframe
            src={`https://e.widgetbot.io/channels/${project.discordServerId}`}
            width="100%"
            height="100%"
          />
        ) : (
          <>
            <ConvoList
              users={users}
              onUserClick={handleUserClick}
              projectName={project?.projectName}
              selectedUser={selectedUser}
            />
            <Box w="73%" h="100%" p={4} borderRadius="xl" boxShadow="0 0 10px rgba(0, 0, 0, 0.1)" bg="gray.50">
              <VStack spacing={4} align="stretch" h="100%">
                <HStack spacing={4} w="100%" borderBottom="2px solid" borderColor="gray.200" pb={2}>
                  {selectedUser ? (
                    <ChakraAvatar size="lg" src={selectedUser.photoUrl || selectedUser.photoURL || avatar} />
                  ) : (
                    <ChakraAvatar size="lg" src={logounichat} />
                  )}
                  <Box>
                    <Text fontSize="xl" fontWeight="bold">
                      {selectedUser ? selectedUser.name : project?.projectName}
                    </Text>
                    <Text fontSize="md">
                      {selectedUser ? selectedUser.email : project?.projectDescription}
                    </Text>
                  </Box>
                </HStack>
                <VStack
                  spacing={4}
                  flex="1"
                  flexDirection={"column-reverse"}
                  overflowY="auto"
                  w="100%"
                  css={{
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                  }}
                >
                  {messageData && messageData.length > 0 ? (
                    [...messageData].reverse().map((message, index) => (
                      <Flex key={index} w="100%" justify={message.sender === currentuser ? "flex-end" : "flex-start"} mb={4} position="relative">
                        {message.sender !== currentuser && (
                          <Tooltip label={userMap[message.sender]?.name} aria-label="Sender name">
                            <ChakraAvatar
                              size="md"
                              src={userMap[message.sender]?.photoUrl || userMap[message.sender]?.photoURL || avatar}
                              mr={3}
                            />
                          </Tooltip>
                        )}
                        <Flex
                          bg={message.sender === currentuser ? "purple.100" : "gray.100"}
                          borderRadius="md"
                          p={3}
                          maxWidth="70%"
                          position="relative"
                          alignItems="center"
                          _hover={{ 
                            "& .timestamp": {
                            opacity: 1,
                            transform: "translateY(0)"
                            }
                        }}
                        >
                          <Text css={{ whiteSpace: 'pre-wrap' }}>
                            {message.message}
                          </Text>
                          <Text
                          className="timestamp"
                            fontSize="xs"
                            position="absolute"
                            bottom="-20px"
                            right={message.sender === currentuser ? "0" : "unset"}
                            left={message.sender !== currentuser ? "0" : "unset"}
                            textAlign={message.sender === currentuser ? "right" : "left"}
                            opacity="0"
                            transform="translateY(10px)"
                            transition="opacity 0.3s, transform 0.3s"
                          >
                            {new Date(message.sentTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          </Text>
                        </Flex>

                        {message.sender === currentuser && (
                          <Tooltip label={userMap[currentuser]?.name} aria-label="Current user name">
                            <ChakraAvatar
                            cursor={"pointer"}
                              size="md"
                              src={userMap[currentuser]?.photoUrl || userMap[currentuser]?.photoURL || avatar}
                              ml={3}
                            />
                          </Tooltip>
                        )}
                      </Flex>
                    ))
                  ) : (
                    <Flex justify="center" align="center" h="100%">
                      <Text fontSize="lg" color="gray.500">No messages yet</Text>
                    </Flex>
                  )}
                </VStack>
                <HStack spacing={2} w="100%">
                  {inputFromChat ? (
                    <Textarea
                      placeholder="Type a message"
                      value={messageInputValue}
                      onChange={(e) => setMessageInputValue(e.target.value)}
                    />
                  ) : (
                    <Input
                      placeholder="Type a message"
                      value={messageInputValue}
                      onChange={(e) => setMessageInputValue(e.target.value)}
                      onKeyDown={(e) => e.key === 'Enter' && onSend()}
                    />
                  )}
                  <IconButton
                      icon={<ArrowForwardIcon />}
                      _hover={{bg:"purple.900",color:"white"}}
                      onClick={onSend} aria-label={''}                  
                    />
                </HStack>

              </VStack>
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
}

export default Chat;
