import React from 'react';
import MultiLayerParallax from './MultiLayerParallax';
import { Box, Text, Button,Image } from '@chakra-ui/react';

import { motion } from 'framer-motion';
import LandingNav from './LandingNav.jsx';
import TimeLine from './TimeLine';
import CoffeeGirl from '../../Assets/aboutimg2.jpeg';
import CoffeeBoy from '../../Assets/aboutimg1.png';
import Collab from '../../Assets/collab.jpeg';
import ReadingGirl from '../../Assets/teacher.png';
import RobotInClass from '../../Assets/AI.jpeg';

export default function LandingPage() {
    
    const AboutPages = [
        {
            message: 'Generative Ideas (G-Ideas) is a knowledge-building environment for students to work as research teams. It offers an integrated education interface that connects peer collaboration and human-AI interaction, with teachers in the loop.',
            images: [ CoffeeBoy, CoffeeGirl ]
        },
        {
            message: 'With Generative Ideas, teams can collaborate effectively, manage projects efficiently, and brainstorm ideas effortlessly with the help of AI.',
            images: [ CoffeeGirl, Collab ]
        },
        {
            message: 'Generative Ideas empowers educators to oversee student activities, monitor progress, and facilitate seamless communication, enhancing classroom efficiency and student engagement.',
            images: [ Collab, ReadingGirl ]
        },
        {
            message: 'With AI assistance, students can harness their creativity and brainstorm innovative ideas, fostering a collaborative learning environment and enhancing problem-solving skills.',
            images: [ ReadingGirl, RobotInClass ]
        }
    ];

    return (
        <>
            <LandingNav />
            <TimeLine />
            <MultiLayerParallax />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                zIndex="9999"
                bgGradient="linear(to-br, black, purple.800, purple.700, purple.600, purple.500, purple.400)"
                flexDirection="column"
            >
                <Text
                    fontFamily='"Kode Mono", monospace'
                    fontWeight="100"
                    textAlign="center"
                    color="white"
                    fontSize="6xl"
                    mb={2}
                    mt={24}
                    noOfLines={1}
                >
                    About Us
                </Text>

                {AboutPages.map(({ message, images }, index) => (
                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ delay: 0.15 }}
                        exit={{ opacity: 0 }}
                        key={`about-page-${index}`}
                    >
                        <Box
                            display="flex"
                            gap="3rem"
                            py="1rem"
                            flexDirection={index % 2 ? 'row' : 'row-reverse'}
                            justifyContent="center"
                            alignItems="center"
                            ml="10rem"
                            mr="2rem"
                        >
                            <Text
                                fontFamily='"Kode Mono", monospace'
                                fontWeight="100"
                                textAlign="left"
                                color="white"
                                my={2}
                                fontSize="2xl"
                            >
                                {message}
                            </Text>
                            <Box
                                position="relative"
                                p="3rem"
                                cursor={'pointer'}
                                _hover={{ transform: 'rotate(2deg)', transition: 'transform 0.5s' }}
                            >
                                {images.map((ele, imgIndex) => (
                                    <Box
                                        key={`about${index}__img${imgIndex}`}
                                        position={imgIndex === 0 ? 'absolute' : 'relative'}
                                        borderRadius="2xl"
                                        transform={`rotate(${10 * imgIndex}deg) translate(${-2 + 3 * imgIndex}rem, -1rem)`}
                                        overflow="hidden"
                                        boxShadow="lg"
                                    >
                                        <Image src={ele} width={1000} height={350} />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </motion.div>
                ))}
                <Box mt={10} />
            </Box>
        </>
    );
}
