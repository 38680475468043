import { PUBLIC_URL } from '../../commons/utils.tsx';
import axios from 'axios';
export const sendUserData = async (userData) => {
  try {
    const data = await axios.post(PUBLIC_URL+ '/api/student', userData)
    console.log(data);
    return data.data;
  } catch (error) {
    console.error('Error sending user data:', error);
    throw error; 
  }
};

export const getStudentByEmail = async (email: string) => {
  try {
    const response = await fetch(`${PUBLIC_URL}/api/student/${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('Student not found');
      }
      throw new Error('Error fetching student');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching student:', error);
    throw error;
  }
};

export const deleteStudentFromClassProjects = async (projectIds, studentId) => {
  try {
    const response = await axios.post(`${PUBLIC_URL}/api/projects/remove-student`, {
      projectIds,
      studentId,
    });
    return response.data;
  } catch (error) {
    console.error('Error removing student from projects:', error);
    throw new Error('Failed to remove student from projects');
  }
};

export const deleteStudentFromClass = async (classId, studentId) => {
  try {
    const response = await axios.post(`${PUBLIC_URL}/api/classes/${classId}/remove-student`, {
      studentId,
    });
    return response.data;
  } catch (error) {
    console.error('Error removing student from class:', error);
    throw new Error('Failed to remove student from class');
  }
};

export const deleteStudentFromGroup = async (groupId, studentId) => {
  try {
    const response = await axios.post(`${PUBLIC_URL}/api/groups/${groupId}/remove-student`, {
      studentId,
    });
    return response.data;
  } catch (error) {
    console.error('Error removing student from Group:', error);
    throw new Error('Failed to remove student from Group');
  }
};

export const postQuestion = async (questionData, studentId, classId) => {
  try {
    const response = await axios.post(`${PUBLIC_URL}/api/addQuestion/`, {
      questionData,  
      studentId,
      classId,  
    });
    return response.data;
  } catch (error) {
    console.error('Error posting question:', error);
    throw new Error('Failed to post the question');
  }
};

export const postSRSData = async (projectId, projectDescription, srsData, studentId, isNonFunctionalRequired) => {
  try {
    
    const response = await axios.post(`${PUBLIC_URL}/api/postsrstodb/`, {
      projectId,
      projectDescription,
      srsData,
      studentId,
      isNonFunctionalRequired,
    });
    return response.data; 
  } catch (error) {
    console.error('Error posting SRS data:', error);
    throw new Error('Failed to post the SRS data'); 
  }
};

export const getQuestionsByStudentId = async (studentId) => {
  try {
    const response = await axios.get(`${PUBLIC_URL}/api/getQuestions/${studentId}`);
    
    return response.data; 
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw new Error('Failed to fetch questions');
  }
};

export const fetchAssignmentsForStudent = async (studentId) => {
  try {
    const response = await axios.get(`${PUBLIC_URL}/api/assignments/student/${studentId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching assignments for students', error);
    throw new Error('failed to fetch assignments');
  }
};

export const fetchSRSforProject = async (projectId) => {
  try {
    const response = await axios.get(`${PUBLIC_URL}/api/srs/${projectId}`);
    return response;
  } catch (error) {
    console.error('Error fetching ', error);
    throw new Error('failed to fetch ');
  }
};



export const updateStudentProfile = async (userId, userData) => {
  try {
   const response =  await axios.put(`${PUBLIC_URL}/api/student/${userId}`, userData);
   return response;
  } catch (error) {
    console.error('Error updating Student profile:', error);
    throw new Error('Failed to update Student profile');
  }
};