import React, { useState } from 'react';
import { Box, Flex, IconButton, Input, VStack, Text, CircularProgress, Image } from '@chakra-ui/react';
import { FiSend, FiX } from 'react-icons/fi';
import chatgptwhite from '../../../Assets/gptwhite.png'; 
import { Rnd } from 'react-rnd';
import { getChatGPTResponseForSRS } from '../../../Services/ChatGPT/Teacher/ChatGPTService.tsx';
import { getSRSFromGPT, regenerateSRSFromGPT } from '../../../Services/ChatGPT/Student/ChatGPTService.tsx';

const DummyChatBox = ({onClose, srsData, setSRSData}) => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([{ role: 'bot', content: 'How may I help you?' }]);
  const [loading, setLoading] = useState(false);

  
  const handleSendMessage = async () => {
    if (input.trim() === '') return;
    if(input.toLowerCase() === "generate"){
        setMessages(prevMessages => [...prevMessages, { role: 'user', content: input }]);
        setLoading(true);
        setInput('');
        try{
        const response = await regenerateSRSFromGPT(srsData, JSON.stringify(messages));
            let trimmedResponse = response;
        
        if (response.startsWith("```")) {
            trimmedResponse = response.replace(/^```json|```$/g, "");
        }
        trimmedResponse = JSON.parse(trimmedResponse);
        onClose();
        setSRSData(trimmedResponse);

    }catch (error) {
        console.error('Error getting bot response:', error);
        setMessages(prevMessages => [...prevMessages, { role: 'bot', content: 'Sorry, there was an error.' }]);
      }finally{
        setLoading(false); 
      }
    }
    else{
    setMessages(prevMessages => [...prevMessages, { role: 'user', content: input }]);
    setLoading(true);
    const userMessage = input; 
    const combinedData = {
        ...srsData,  
        userMessage: userMessage 
      };
      setInput('');

    try {
      const botResponse = await getChatGPTResponseForSRS(combinedData);
      
      setSRSData(combinedData);
      setMessages(prevMessages => [...prevMessages, { role: 'bot', content: botResponse.data }]);

    } catch (error) {
      console.error('Error getting bot response:', error);
      setMessages(prevMessages => [...prevMessages, { role: 'bot', content: 'Sorry, there was an error.' }]);
    } finally {
      setLoading(false); 
    }
}

    
  };

  
  const [width, setWidth] = useState(450);
  const [height, setHeight] = useState(500);
  const screenWidth = window.screen.width;
  const [x, setX] = useState(() => calculateInitialX());
  const [y, setY] = useState(() => calculateInitialY());
  


  function calculateInitialX() {
    if (window.innerWidth < 1440) {
      return 440; 
    } else {
      return 704; 
    }
  }

  function calculateInitialY() {
    if (window.innerWidth < 1440) {
      return 366; 
    } else {
      return 96; 
    }
  }


  return (
    <Rnd
      size={{ width, height }}
      position={{ x, y }}
      minWidth={300}
      minHeight={300}
      dragHandleClassName="handle"
      onResizeStop={(e, direction, ref, delta, position) => {
        setWidth(ref.style.width);
        setHeight(ref.style.height);
      }}
      onDragStop={(e, d) => {
        setX(d.x);
        setY(d.y);
      }}
      
    >
    <Box
      width="100%"
      height="100%"
      bg="gray.50"
      color="black"
      borderRadius="xl"
      display="flex"
      flexDirection="column"
      boxShadow="2xl"
      fontFamily="initial"
          fontSize="16"
    >
      {/* Chat Header */}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        bg="purple.900"
        p={2}
        borderTopRadius="xl"
        color="white"
        className='handle'
      >
        <Flex alignItems="center">
          <Image src={chatgptwhite} boxSize="40px" mr={2} />
          <Text fontWeight="bold" fontSize="xl">SRS AI</Text>
        </Flex>
        <IconButton
          size="sm"
          icon={<FiX />}
          aria-label="Close"
          variant="ghost"
          color="white"
          onClick={onClose}
        />
      </Flex>

      {/* Chat Body */}
      <Flex direction="column" flex="1" overflowY="auto">
        <VStack
          spacing={2}
          p={2}
          flex="1"
          overflowY="auto"
          bg="#f7f7f8"
          width="100%"
          position="relative"
          flexDirection={"column-reverse"}
        >
          {messages.length === 0 && <Text>No messages yet</Text>}
         
          {[...messages].reverse().map((msg, idx) => (
            <Box
              key={idx}
              bg={msg.role === 'user' ? '#d5ebfd' : '#f0f4fc'}
              p={2}
              m={2}
              borderRadius="xl"
              alignSelf={msg.role === 'user' ? 'flex-end' : 'flex-start'}
              maxWidth="80%"
              display="flex"
              flexDirection="column"
              color="black"
              wordBreak="break-word"
              overflowWrap="break-word"
            >
              <Text>{msg.content}</Text>
            </Box>
          ))}
        </VStack>
      </Flex>

      {/* Chat Input */}
      <Flex
        p={2}
        borderTop="1px solid"
        borderColor="gray.200"
        alignItems="center"
        bg="#f7f7f8"
      >
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSendMessage();
            }
          }}
          placeholder="Type your message..."
          flex="1"
          height="40px"
        />
        <IconButton
          size="sm"
          icon={<FiSend />}
          aria-label="Send message"
          onClick={handleSendMessage}
          ml={2}
          bg="purple.900"
          color="white"
          isLoading={loading}
          _hover={{bg:'purple.900', color:'white'}}
        />
      </Flex>
    </Box>
    </Rnd>
  );
};

export default DummyChatBox;
