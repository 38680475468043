import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

var apiKey = process.env.REACT_APP_AUTH_APIKEY;
var authDomain = process.env.REACT_APP_AUTH_AUTHDOMAIN;
var projectId = process.env.REACT_APP_AUTH_PROJECTID;
var storageBucket = process.env.REACT_APP_AUTH_STORAGEBUCKET;
var messagingSenderId = process.env.REACT_APP_AUTH_MESSAGINGSENDERID;
var appId = process.env.REACT_APP_AUTH_APPID;
var measurementId = process.env.REACT_APP_AUTH_MEASUREMENTID;


const firebaseConfig = {
  apiKey:  apiKey,
  authDomain:  authDomain,
  projectId:  projectId,
  storageBucket:  storageBucket,
  messagingSenderId:  messagingSenderId,
  appId:  appId,
  measurementId:  measurementId
};


// Initialize Firebase
const app = initializeApp(firebaseConfig,"app");
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { app, auth, provider };

