// src/components/protectedRoute/ProtectedRoute.tsx
import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { getTeacherByEmail } from '../../Services/Teacher/teacherService.tsx';
import { getAuth } from 'firebase/auth';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null); 

  if (loading) {
    return <div>Loading...</div>; 
  }

  const fetchGoogleAccessToken = async () => {
    const auth = getAuth();
    if (user) {
      const tokenResult = await user.getIdTokenResult(true);
      const token = tokenResult.token;
      sessionStorage.setItem("googleAccessToken", token);
      sessionStorage.setItem("TokenExpirationTime", tokenResult.expirationTime);
    }
  };

  if (user) {
    const checkTeacherAuthorization = async () => {
      try {
        if (!sessionStorage.getItem("googleAccessToken")) {
          await fetchGoogleAccessToken();
        }
        const teacher = await getTeacherByEmail(user.email);
        if (teacher) {
          sessionStorage.setItem("CurrentLoggedInTId", teacher._id);
          sessionStorage.setItem("userType","teacher");
          sessionStorage.setItem("teacherUserData", JSON.stringify(teacher));
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false); 
        }
      } catch (error) {
        console.error("Error fetching teacher data:", error);
        setIsAuthorized(false); 
      }
    };
    checkTeacherAuthorization();
  }

  return isAuthorized === null ? null : isAuthorized ? element : <Navigate to={`/teacher-portal/login?redirect=${location.pathname}`} />;
};

export default ProtectedRoute;
