import React, { useState, useEffect } from 'react';
import { Box, Flex, IconButton, Input, VStack, Text, Image, Avatar } from '@chakra-ui/react';
import { FiSend, FiX, FiMessageSquare, FiHome } from 'react-icons/fi';
import chatgptwhite from '../../../Assets/gptwhite.png'; 
import { Rnd } from 'react-rnd';
import { getChatGPTResponseForSRS, regenerateSRSFromGPT } from '../../../Services/ChatGPT/Student/ChatGPTService.tsx';
import giimage from '../../../Assets/G-Ideas.png'

const DummyChatBox = ({ onClose, srsData, setSRSData, isNonFunctionalRequired, projectBackLog, projectDescription, currentTeacherId }) => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([{ role: 'bot', content: 'Have all the inputs been specified?' }]);
  const [loading, setLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track current question index
  const [showQuestions, setShowQuestions] = useState(false); // Toggle between chat messages and question set
  const [width, setWidth] = useState(450);
  const [height, setHeight] = useState(500);
  const screenWidth = window.screen.width;
  const [x, setX] = useState(() => calculateInitialX());
  const [y, setY] = useState(() => calculateInitialY());
  const [noEntered, setNoEntered] = useState(false);
  const [askedRegenerate, setAskedRegenerate] = useState(false);
  const [missingItem, setMissingItem] = useState(''); 


  function calculateInitialX() {
    return window.innerWidth < 1440 ? 440 : 705; 
  }

  function calculateInitialY() {
    return window.innerWidth < 1440 ? 366 : 100; 
  }

  const questions = [
    "Has the requirement specified  all the inputs?",
    "Has the requirement specified all the actions the system should perform?",
    "Has the requirement specified all the conditions the system should check?",
    "Has the requirement specified all data updates?",
    ...(isNonFunctionalRequired ? [
      "Is the requirement unambiguous?",
      "Is the requirement testable?"
    ] : []),
  ];

  const staticNoResponses = {
    0: "Please let me know what inputs are missing.",
    1: "Please let me know what actions are missing.",
    2: "Please let me know what conditions are missing.",
    3: "Please let me know what data updates are missing.",
    4: "Please clarify the ambiguous requirements.",
    5: "Please specify how we can test the requirement."
  };

  const sendMessageWithDelay = async (message) => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2-second delay
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'bot', content: message }
    ]);
    setLoading(false);
  };

  const handleSendMessage = async (userResponse = input) => {
    if (userResponse.trim() === '') return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: userResponse },
    ]);
    setInput('');

    if (noEntered && askedRegenerate) {
      if (userResponse.toLowerCase() === 'yes') {
        handleRegenerate();
      } else {
        moveToNextQuestionOrFinish();
      }
    } else if (noEntered) {
      setMissingItem(userResponse);
      setAskedRegenerate(true);
      handleMissingItemsResponse();
    } else {
      if (userResponse.toLowerCase() === 'no') {
        setNoEntered(true);
        await sendMessageWithDelay(staticNoResponses[currentQuestionIndex]);
      } else {
        moveToNextQuestionOrFinish();
      }
    }
  };

  const moveToNextQuestionOrFinish = async () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      await sendMessageWithDelay(questions[currentQuestionIndex + 1]);
      setNoEntered(false);
      setAskedRegenerate(false);
    } else {
      await sendMessageWithDelay("All questions answered");
      onClose();
    }
  };

  const handleRegenerate = async () => {
    setLoading(true);
    try {
      const response = await regenerateSRSFromGPT(srsData, missingItem, isNonFunctionalRequired, projectBackLog, projectDescription, currentTeacherId);
      let trimmedResponse = response;

      if (response.startsWith('```')) {
        trimmedResponse = response.replace(/^```json|```$/g, '');
      }
      trimmedResponse = JSON.parse(trimmedResponse);
      setSRSData(trimmedResponse);
      await sendMessageWithDelay('SRS has been regenerated based on your inputs.');
      onClose();
    } catch (error) {
      console.error('Error regenerating SRS:', error);
      await sendMessageWithDelay('Sorry, there was an error regenerating the SRS.');
    } finally {
      setLoading(false);
    }
  };

  const handleMissingItemsResponse = async () => {
    await sendMessageWithDelay("Would you like to regenerate the SRS? Type 'yes' to regenerate or 'no' to finish.");
  };

  return (
    <Rnd
      size={{ width, height }}
      position={{ x, y }}
      minWidth={300}
      minHeight={300}
      dragHandleClassName="handle"
      onResizeStop={(e, direction, ref, delta, position) => {
        setWidth(ref.style.width);
        setHeight(ref.style.height);
      }}
      onDragStop={(e, d) => {
        setX(d.x);
        setY(d.y);
      }}
    >
      <Box width="100%" height="100%" bg="gray.50" color="black" borderRadius="xl" display="flex" flexDirection="column" boxShadow="2xl" fontFamily="initial" fontSize="16">
        {/* Chat Header */}
        <Flex className='handle' cursor="move" alignItems="center" justifyContent="space-between" bg="purple.900" p={2} borderTopRadius="xl" color="white">
          <Flex alignItems="center">
           
            <Text ml={2} fontWeight="bold" fontSize="xl">SRS AI</Text>
          </Flex>
          <Flex>
            <IconButton size="sm" icon={<FiX />} aria-label="Close" variant="ghost" color="white" onClick={onClose} />
          </Flex>
        </Flex>

        {/* Chat Body */}
        <VStack p={2} flex="1" overflowY="auto" bg="#f7f7f8" width="100%" position="relative" flexDirection={"column-reverse"}>
        {[...messages].reverse().map((msg, idx) => (
          <Flex key={idx} align="center" justify={msg.role === 'user' ? 'flex-end' : 'flex-start'} w="100%">
            {msg.role !== 'user' && (
              <Avatar
                name="GI Avatar" 
                src={giimage}
                size="sm"
                mr={2}
              />
            )}
            <Box bg={msg.role === 'user' ? '#d5ebfd' : '#f0f4fc'} p={2} m={2} borderRadius="xl" maxWidth="80%" color="black" wordBreak="break-word">
              <Text>{msg.content}</Text>
            </Box>
          </Flex>
        ))}
      </VStack>

        {/* Chat Input */}
        <Flex p={2} borderTop="1px solid" borderColor="gray.200" alignItems="center" bg="#f7f7f8">
          <Input value={input} onChange={(e) => setInput(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') handleSendMessage(); }} placeholder="Type your message..." flex="1" height="40px" />
          <IconButton size="sm" icon={<FiSend />} aria-label="Send message" onClick={() => handleSendMessage()} ml={2} bg="purple.900" color="white" isLoading={loading} />
        </Flex>
      </Box>
    </Rnd>
  );
};

export default DummyChatBox;
