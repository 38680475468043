import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const LandingNav = () => {
    const navigate = useNavigate();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="fixed"
            top="0"
            left="0"
            width="full"
            bgColor="rgba(255, 255, 255, 0.1)"
            zIndex="1000"
            p="2"
        >
            <Text
                fontFamily='"Kode Mono", monospace'
                fontWeight="400"
                color="white"
                p="1"
                m="2"
                border="1px solid transparent"
                _hover={{
                    bg: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "md",
                    boxShadow: "md",
                    backdropFilter: "blur(5px)",
                    color: "textcolor",
                    transition: "background 0.5s ease",
                    cursor: "pointer"
                }}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
                Home
            </Text>
            <Text
                fontFamily='"Kode Mono", monospace'
                fontWeight="400"
                color="white"
                p="1"
                m="2"
                border="1px solid transparent"
                _hover={{
                    bg: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "md",
                    boxShadow: "md",
                    backdropFilter: "blur(5px)",
                    color: "textcolor",
                    transition: "background 0.5s ease",
                    cursor: "pointer"
                }}
                onClick={() => navigate('/student-portal/login')}
            >
                Student Portal
            </Text>
            <Text
                fontFamily='"Kode Mono", monospace'
                fontWeight="400"
                color="white"
                p="1"
                m="2"
                border="1px solid transparent"
                _hover={{
                    bg: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "md",
                    boxShadow: "md",
                    backdropFilter: "blur(5px)",
                    color: "textcolor",
                    transition: "background 0.5s ease",
                    cursor: "pointer"
                }}
                onClick={() => navigate('/teacher-portal/login')}
            >
                Teacher Portal
            </Text>
        </Box>
    );
};

export default LandingNav;
