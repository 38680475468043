import { PUBLIC_URL } from '../../../commons/utils.tsx';
import axios from 'axios';
export const getChatGPTResponse = async (messages, currentTeacherId) => {
  try {
    const response = await axios.post(`${PUBLIC_URL}/api/student/getChatGPTResponse/${currentTeacherId}`, { messages });
    return response.data;
  } catch (error) {
    console.error('Error getting chatGPT message:', error);
    throw new Error('Failed to get chatGPT message');
  }
};


  export const appendGPT = async (messages, sId) => {
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/student/storeChatGPTResponse`, { messages,sId });
      return response;
    } catch (error) {
      console.error('Error storing chatGPT message:', error);
      throw new Error('Failed to store chatGPT message');
    }
  }

  export const appendProjectGPT = async (messages, pId) => {
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/student/storeChatGPTResponseToProject`, { messages,pId });
      return response;
    } catch (error) {
      console.error('Error storing chatGPT message:', error);
      throw new Error('Failed to store chatGPT message');
    }
  }

  export const appendGroupGPT = async (messages, gId) => {
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/student/storeChatGPTResponseToGroup`, { messages,gId });
      return response;
    } catch (error) {
      console.error('Error storing chatGPT message:', error);
      throw new Error('Failed to store chatGPT message');
    }
  }

  export const getQuestionsFromGPT = async (subject, firstTopic, difficulty,currentTeacherId, previousQuestion = null) => {
    try {
      
      const response = await axios.post(`${PUBLIC_URL}/api/student/getquestionsfromgpt`, {
        subject: subject,
        difficulty: difficulty,
        previousQuestion: previousQuestion,
        topic: firstTopic,
        teacherId: currentTeacherId,
      });
      return response.data;
    } catch (error) {
      console.error('Error getting  chatGPT questions:', error);
      throw new Error('Failed to get chatGPT questions:');
    }
  };


  export const getTopicsFromGPT = async (subject, currentTeacherId) => {
    try {
     
      const response = await axios.post(`${PUBLIC_URL}/api/student/gettopicsfromgpt`, {
        subject: subject,
        teacherId: currentTeacherId,
      });
      return response.data;
    } catch (error) {
      console.error('Error getting  chatGPT topics:', error);
      throw new Error('Failed to get chatGPT topics:');
    }
  };

  
  export const getSRSFromGPT = async (description, backlog, isNonFunctionalRequired, currentTeacherId) => {
    try {
      
      const response = await axios.post(`${PUBLIC_URL}/api/student/getsrsfromgpt`, {
        description: description,
        backlog: backlog,
        isNonFunctionalRequired: isNonFunctionalRequired,
        teacherId: currentTeacherId,
        
        
      });
      return response.data;
    } catch (error) {
      console.error('Error getting  chatGPT SRS:', error);
      throw new Error('Failed to get chatGPT SRS:');
    }
  };

  export const regenerateSRSFromGPT = async (srsData, missingItem, isNonFunctionalRequired, projectBackLog, projectDescription, currentTeacherId) => {
    try {
      
      const response = await axios.post(`${PUBLIC_URL}/api/student/regeneratesrsfromgpt`, {
        srsData: srsData,
        missingItem: missingItem,
        isNonFunctionalRequired:isNonFunctionalRequired,
        projectBackLog: projectBackLog,
        projectDescription: projectDescription,
        teacherId: currentTeacherId,
        
      });
      return response.data;
    } catch (error) {
      console.error('Error getting  chatGPT SRS:', error);
      throw new Error('Failed to get chatGPT SRS:');
    }
  };

  