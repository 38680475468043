import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  Avatar,
  Image,
  Button,
  useDisclosure,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Textarea,
  Divider,
  FormControl,FormLabel,HStack,Checkbox, useToast, IconButton, FormErrorMessage,Select,Fade, Menu, MenuButton, MenuList, MenuItem, SimpleGrid,
  
} from '@chakra-ui/react';
import { AddIcon,EditIcon } from '@chakra-ui/icons';
import TDashboard from '../Dashboard/Dashboard.tsx';
import { Navigate } from 'react-router-dom';
import { getProjectsByIds, getTeachersByIds, getStudentsByIds, getClassById, addTeachersToProject, addStudentsToProject, updateProject, deleteTeacherFromClassProjects, fetchAnnouncementsForProject, addCommentToProjectAnnouncement, addAnnouncementToProject } from '../../../Services/Teacher/teacherService.tsx';
import Chat from "./Chat.tsx";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { deleteStudentFromClassProjects } from '../../../Services/Student/studentService.tsx';
import { pusherClient } from '../../../pusherConfig.js';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import GroupCard from './GroupCard.tsx';
import SRSHistory from './SRSHistory.jsx';

const ProjectView = () => {
  const [projectDetails, setProjectDetails] = useState(null);
  const [classDetails, setClassDetails] = useState(null);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editError, setEditError] = useState('');
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [teacherLoading, setTeacherLoading] = useState(false);
  const [studentLoading, setStudentLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { classId, projectId } = useParams();
  const { isOpen: isEditProjectOpen, onOpen: onEditProjectOpen, onClose: onEditProjectClose } = useDisclosure();
  const { isOpen: isAddStudentsOpen, onOpen: onAddStudentsOpen, onClose: onAddStudentsClose } = useDisclosure();
  const { isOpen: isAddTeachersOpen, onOpen: onAddTeachersOpen, onClose: onAddTeachersClose } = useDisclosure();
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [classTeachers, setClassTeachers] = useState([]);
  const [classStudents, setClassStudents] = useState([]);  
  const location = useLocation();
  const navigate = useNavigate();
  const [inputFromChat, setInputFromChat] = useState('');
  const currentLoggedInTId = sessionStorage.getItem("CurrentLoggedInTId").trim();
 
  const [editProjectData, setEditProjectData] = useState({
    projectName: '',
    projectGoal: '',
    projectDescription:'',
    projectBranding:'',
    chatOption: '',
    discordServerId: '',
  });
  const toast = useToast();

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const data = await getProjectsByIds([projectId]);
        setProjectDetails(data[0]);
        console.log(data[0]);
        const res = await getClassById(classId);
        setClassDetails(res);
        let groups = data[0].groups.filter(x=>x.teacherIds.includes(currentLoggedInTId));
        setGroups(groups);
      } catch (error) {
        console.error('Error fetching project details:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
      const at =  sessionStorage.getItem("activeTabInProject");
    if(at){
      setActiveTab(parseInt(at));
    }
    };

    fetchProjectDetails();
  }, [projectId]);

  const clearPrefillMessage = () => {
    setInputFromChat(null);
  };

  useEffect(() => {
    if (location.state && location.state.prefillMessage) {
      console.log(location.state.prefillMessage);
      setInputFromChat(location.state.prefillMessage);
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, location.pathname, navigate]);

  const fetchTeachers = async (teacherIds) => {
    setTeacherLoading(true);
    try {
      const data = await getTeachersByIds(teacherIds);
      setTeachers(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setError(error.message);
    } finally {
      setTeacherLoading(false);
    }
  };

  const fetchClassTeachers = async (teacherIds) => {
    setTeacherLoading(true);
    try {
      const data = await getTeachersByIds(teacherIds);
      setClassTeachers(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setError(error.message);
    } finally {
      setTeacherLoading(false);
    }
  };

  const fetchStudents = async (studentIds) => {
    setStudentLoading(true);
    try {
      const data = await getStudentsByIds(studentIds);
      setStudents(data);
    } catch (error) {
      console.error('Error fetching students:', error);
      setError(error.message);
    } finally {
      setStudentLoading(false);
    }
  };

  const fetchClassStudents = async (studentIds) => {
    setStudentLoading(true);
    try {
      const data = await getStudentsByIds(studentIds);
      setClassStudents(data);
    } catch (error) {
      console.error('Error fetching students:', error);
      setError(error.message);
    } finally {
      setStudentLoading(false);
    }
  };

  const handleTeacherSelection = (teacherId) => {
    setSelectedTeachers((prevSelected) =>
      prevSelected.includes(teacherId)
        ? prevSelected.filter((id) => id !== teacherId)
        : [...prevSelected, teacherId]
    );
  };
  const handleStudentSelection = (studentId) => {
    setSelectedStudents((prevSelected) =>
      prevSelected.includes(studentId)
        ? prevSelected.filter((id) => id !== studentId)
        : [...prevSelected, studentId]
    );
  };
  const [sendloading, setSendLoading] = useState(false);
  const handleInviteTeachers = async () => {
    if (selectedTeachers.length === 0) {
      toast({
        description: 'Please select at least one email address.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setSendLoading(true);
  
    try {
      await addTeachersToProject(projectId, selectedTeachers);
      const updatedTeacherIds = [...projectDetails.teacherIds, ...selectedTeachers];
      fetchTeachers(updatedTeacherIds);
  
      toast({
        description: 'Teachers invited and added to the project successfully.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: 'Failed to invite teachers.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setSendLoading(false);
      onAddTeachersClose();
      setSelectedTeachers([]);
    }
  };

  const handleInviteStudents = async () => {
    if (selectedStudents.length === 0) {
      toast({
        description: 'Please select at least one email address.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setSendLoading(true);
  
    try {
      await addStudentsToProject(projectId, selectedStudents);
      const updatedStudentIds = [...projectDetails.studentIds, ...selectedStudents];
      fetchStudents(updatedStudentIds);
  
      toast({
        description: 'Students invited and added to the project successfully.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: 'Failed to invite Students.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setSendLoading(false);
      onAddStudentsClose();
      setSelectedStudents([]);
    }
  };

  const handleTeacherInviteClick = () => {
    fetchClassTeachers(classDetails.teachers);
    onAddTeachersOpen();

  }
  const handleStudentInviteClick = () => {
    fetchClassStudents(classDetails.students);
    onAddStudentsOpen();

  }

  useEffect(() => {
    if (activeTab === 2 && projectDetails) {
      fetchTeachers(projectDetails.teacherIds);
      fetchStudents(projectDetails.studentIds);
    }
  }, [activeTab, projectDetails]);

  useEffect(() => {
    if (isEditProjectOpen && projectDetails) {
      setEditProjectData({
        projectName: projectDetails.projectName,
        projectGoal: projectDetails?.projectGoal,
        projectBranding: projectDetails?.projectBranding,
        projectDescription: projectDetails.projectDescription,
        chatOption: projectDetails.nativeChat ? 'Native Chat' : 'Discord Server',
        discordServerId: projectDetails.discordServerId || '',
      });
    }
  }, [isEditProjectOpen, projectDetails]);
  

  const handleDropdownChange = (e) => {
    const value = e.target.value;
    setEditProjectData((prevData) => ({
      ...prevData,
      chatOption: value,
      discordServerId: value === 'Discord Server' ? prevData.discordServerId : '',
    }));
  };

  


  const validateFields = () => {
    if (!editProjectData.projectName) {
      return 'Project name is required.';
    }
    if (!editProjectData.projectGoal) {
      return 'Project Goal is required.';
    }
    if (!editProjectData.chatOption) {
      return 'Please select a chat option.';
    }
    if (editProjectData.chatOption === 'Discord Server' && !editProjectData.discordServerId) {
      return 'Discord server ID is required if Discord Server is selected.';
    }
    return ''; 
  };

  
  const handleDeleteTeacher = async (teacherId) => {
    if (teacherId === currentLoggedInTId) {
      toast({
        title: "Error deleting teacher.",
        description: "You cannot delete the currently logged-in user.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setTeacherLoading(true);
    try {
      await deleteTeacherFromClassProjects([projectDetails._id], teacherId)
      setTeachers((prevTeachers) => prevTeachers.filter((teacher) => teacher._id !== teacherId));
      
      toast({
        title: "Teacher removed.",
        description: "The teacher has been removed successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setTeacherLoading(false);
    } catch (error) {
      console.error(error);
      toast({
        title: "Error deleting teacher.",
        description: "There was an error deleting the teacher.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleDeleteStudent = async (studentId) => {
    setStudentLoading(true);
    try {
      await deleteStudentFromClassProjects([projectDetails._id], studentId)
      setStudents((prevStudents) => prevStudents.filter((student) => student._id !== studentId));

      toast({
        title: "Student removed.",
        description: "The Student has been removed successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setStudentLoading(false);
    } catch (error) {
      console.error(error);
      toast({
        title: "Error deleting Student.",
        description: "There was an error deleting the Student.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };



  const handleEditProjectChange = (e) => {
    const { name, value } = e.target;
    setEditProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditProjectSubmit = async () => {
    const errorMessage = validateFields();
    if (errorMessage) {
      setEditError(errorMessage);
      setTimeout(() => setEditError(''), 2000);
      return;
    }
  
    try {
      const response = await updateProject(projectDetails._id, editProjectData);
      setProjectDetails(response);
      toast({
        description: 'Project details updated successfully.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onEditProjectClose();
      window.location.reload();
    } catch (error) {
      toast({
        description: 'Failed to update project details.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };
  

  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState('');
  const [newComment, setNewComment] = useState({});
  const [commentErrors, setCommentErrors] = useState({});
  const [showComments, setShowComments] = useState({});
  const [announcementError, setAnnouncementError] = useState('');
  const cleanPhotoURL = (url) => {
    const plink = url.replace(/['"]/g, '');  
    return plink;
  };
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  useEffect(() => {
    if (!projectId) return;
  
    const announcementChannel = pusherClient.subscribe(`projectannouncements-${projectId}`);
  
    const handleNewAnnouncements = (newAnnouncements) => {
      const newAnnouncement = newAnnouncements.announcements[0]; 
      setAnnouncements(prevAnnouncements => {
        const announcementExists = prevAnnouncements.some(announcement => announcement._id === newAnnouncement._id);
        const updatedAnnouncements = announcementExists
          ? prevAnnouncements.map(announcement => 
              announcement._id === newAnnouncement._id ? newAnnouncement : announcement
            )
          : [...prevAnnouncements, newAnnouncement];
        const sortedData = updatedAnnouncements.sort((a, b) => new Date(b.time) - new Date(a.time));
  
        return sortedData;
      });
    };
  
    announcementChannel.bind('projectannouncements:new', handleNewAnnouncements);
  
    return () => {
      announcementChannel.unbind('projectannouncements:new', handleNewAnnouncements);
      pusherClient.unsubscribe(`projectannouncements-${projectId}`);
    };
  }, [classId]);
  

  const handleCommentChange = (announcementId, value) => {
    setNewComment(prevState => ({
      ...prevState,
      [announcementId]: value
    }));
  };

  const toggleComments = (id) => {
    setShowComments(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  

  useEffect(() => {
    fetchAnnouncements();
  }, [projectId]);
  const fetchAnnouncements = async () => {
    try {
      const data = await fetchAnnouncementsForProject(projectId);
      const sortedData = data.sort((a, b) => new Date(b.time) - new Date(a.time));
      setAnnouncements(sortedData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleCommentSubmit = async (announcementId) => {
    const comment = newComment[announcementId] || '';
  
  if (comment.trim() === '') {
    setCommentErrors(prevState => ({
      ...prevState,
      [announcementId]: 'Comment cannot be empty.'
    }));
    setTimeout(() => {
      setCommentErrors(prevState => ({
        ...prevState,
        [announcementId]: ''
      }));
    }, 2000); 

    return;
  }

  
    try {
      await addCommentToProjectAnnouncement(announcementId, {
        userId: currentLoggedInTId,  
        comment: comment,
        userModel: "Teacher",
        projectId: projectId,
      });
      fetchAnnouncements(); 
      setNewAnnouncement(''); 
      setAnnouncementError(''); 
      setNewComment('');
      setCommentErrors({});
    } catch (error) {
      console.error('Error submitting comment:', error);
      
    }
  };

  const handleAnnouncementSubmit = async () => {
    if (newAnnouncement.trim() === '') {
      setAnnouncementError('Announcement cannot be empty.');
      setTimeout(() => {
        setAnnouncementError('');
      }, 2000); 
      return;
    }
  
    try {
      
      await addAnnouncementToProject(projectId, {
        userId: currentLoggedInTId,  
        announcement: newAnnouncement,
        comments: [],
        userModel: "Teacher",
      });
      fetchAnnouncements(); 
      setNewAnnouncement(''); 
      setAnnouncementError(''); 
    } catch (error) {
      console.error('Error submitting announcement:', error);
      
    }
  };


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }
  const handleTabChange = (index) => {
    setActiveTab(index);
    sessionStorage.setItem("activeTabInProject", index.toString());
  };
  return (
    <TDashboard>
      <Box p={4} textColor={'purple.900'}>
        <Tabs marginTop={16}   index={activeTab} onChange={handleTabChange}>
          <TabList mb={2}>
            <Tab cursor={'pointer'}>Announcements</Tab>
            <Tab>Groups</Tab>
            <Tab>People</Tab>
            <Tab>Chat</Tab>
            <Tab>SRSHistory</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box p={4}>
              <Box
                  position="relative"
                  w="full"
                  h="200px"
                  bg="gray.200"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mb={6}
                >
                  <Image
                    borderRadius="md"
                    objectFit="cover"
                    w="full"
                    h="full"
                    src={projectDetails?.url || ''}
                    alt={projectDetails?.projectName || ''}
                  />
                  <Box
                    position="absolute"
                    bottom={2}
                    left={2}
                    color="white"
                    fontWeight="bold"
                    fontSize="lg"
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Text>{projectDetails?.projectName || ''}</Text>
                    <Text fontSize="md">{projectDetails?.projectGoal || ''}</Text>
                  </Box>
                  <IconButton
                    icon={<EditIcon />}
                    position="absolute"
                    bottom={2}
                    right={2}
                    colorScheme="whiteAlpha"
                    onClick={onEditProjectOpen}
                  />
                </Box>
                <Flex>
   
    <Box
      w="100%"
      p={4}
      bg="white"
    >
      <Box mb={4} p={4} borderWidth="1px" borderRadius="lg" boxShadow={"md"} bg="white">
        {/* <Heading size="md" mb={4}>Create Announcement</Heading> */}
        <Textarea
          placeholder="Write a new announcement..."
          value={newAnnouncement}
          onChange={(e) => {
            setNewAnnouncement(e.target.value);
            setAnnouncementError(''); 
          }}
          isInvalid={announcementError.length > 0}
          errorBorderColor="red.300"
        />
        <Text color="red.500" mt={2}>{announcementError}</Text>
        <Button mt={2} _hover={{ background: "purple.900", color: "white" }} onClick={handleAnnouncementSubmit} cursor={"pointer"}>Post</Button>
      </Box>

      <Box width="100%">
      <VStack spacing={4} align="start">
        {announcements.map(announcement => (
          <Box
            width="100%"
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="md"
            key={announcement._id}
            bg="white"
          >
            <Flex align="center" mb={2}>
              <Avatar src={cleanPhotoURL(announcement.user.photoURL)} size="sm" mr={2} />
              <Box>
                <Text fontSize="md" fontWeight="bold">
                  {announcement.user.name}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {formatDate(announcement.time)}
                </Text>
              </Box>
            </Flex>
            <Text fontSize="sm" fontWeight="normal">
              {announcement.announcement}
            </Text>
            <br />
            <Divider my={4} borderWidth={1} width="calc(100% + 2rem)" ml={-4} />
            {announcement.comments.length > 0 && (
              <Button
              mt={2}
              size={'md'}
              cursor="pointer"
              onClick={() => toggleComments(announcement._id)}
            >
              Class comments
            </Button>
            )}
            {showComments[announcement._id] && announcement.comments.length > 0 && (
              <VStack spacing={2} mt={2} align="start">
                {announcement.comments.map((comment, index) => (
                  <Box key={index} width="100%" mt={2}>
                  <Flex align="center" mb={2}>
                    <Avatar src={cleanPhotoURL(comment.user.photoURL)} size="sm" mr={2} />
                    <Box>
                    <Flex>
                      <Text fontSize="sm" fontWeight="bold">
                        {comment.user.name}
                      </Text>
                      <Text ml={2} fontSize="sm" color="gray.500">
                        {formatDate(comment.time)}
                      </Text>
                      </Flex>
                      <Text>{comment.comment}</Text>
                    </Box>
                  </Flex>
                </Box>
                ))}

              </VStack>
            )}
            <Box width="100%" mt={4}>
              <Textarea
                placeholder="Write a comment..."
                value={newComment[announcement._id] || ''}
                onChange={(e) => {
                  handleCommentChange(announcement._id, e.target.value);
                  setCommentErrors(prevState => ({
                    ...prevState,
                    [announcement._id]: ''
                  }));
                }}
                isInvalid={commentErrors[announcement._id]?.length > 0}
                errorBorderColor="red.300"
              />
              {commentErrors[announcement._id] && (
                <Text color="red.500" mt={2}>
                  {commentErrors[announcement._id]}
                </Text>
              )}
              <Button
                _hover={{ background: 'purple.900', color: 'white' }}
                mt={2}
                onClick={() => handleCommentSubmit(announcement._id)}
              >
                Comment
              </Button>
            </Box>
          </Box>
        ))}
      </VStack>
    </Box>


    </Box>
  </Flex>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box p={4}>
                <Flex mb={4} justify="space-between" align="center">
                  <Heading size="md">Groups</Heading>
                </Flex>
                {groups?.length === 0 ? (
                  <Flex direction="column" align="center" justify="center" height="200px">
                    <AiOutlineExclamationCircle size={40} color="gray.500" />
                    <Text mt={4} fontSize="lg" color="gray.500">
                      No Groups found
                    </Text>
                  </Flex>
                ) : (
                    <VStack spacing={4} align="start">
                    <Box p={4}>
                      <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                        {groups?.map((group,index) => (
                          <GroupCard key={group._id} group={group} classId={classId} projectId={projectDetails._id} index={index}/>
                        ))}
                      </SimpleGrid>
                    </Box>
                  </VStack>
                )}
              </Box>
            </TabPanel>
            <TabPanel>
              <Box p={4}>
                <Heading size="md" mb={4}>People</Heading>
                <Tabs variant="soft-rounded" colorScheme="purple">
                  <TabList>
                    <Tab>Teachers</Tab>
                    <Tab>Students</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                    <Flex justify="space-between" mb={4} align="center">
                        <Heading></Heading>
                        <Button
                        leftIcon={<AddIcon />}
                        bg="purple.900"
                        color="white"
                        _hover={{ bg: 'purple.800' }}
                        onClick={() => handleTeacherInviteClick()}
                        >
                        Invite Teachers
                        </Button>
                    </Flex>
                    <VStack spacing={4} align="start">
      {teacherLoading ? (
        <Spinner size="lg" />
      ) : teachers.length === 0 ? (
        <Text>No teachers found.</Text>
      ) : (
        teachers.map((teacher) => (
          <Flex
            key={teacher._id}
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            align="center"
            width="full"
            justify="space-between" // Add space between to push menu button to the right
          >
            <Flex align="center">
              <Avatar name={teacher.name} src={teacher.photoURL} />
              <Box ml={4}>
                <Text fontWeight="bold">{teacher.name}</Text>
                <Text>{teacher.email}</Text>
              </Box>
            </Flex>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<BiDotsVerticalRounded />}
                size={"md"}
                bg={"white"}
              />
              <MenuList>
                <MenuItem onClick={() => handleDeleteTeacher(teacher._id)}>Delete</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ))
      )}
    </VStack>
                    </TabPanel>
                    <TabPanel>
                    <Flex justify="space-between" mb={4} align="center">
                        <Heading></Heading>
                        <Button
                        leftIcon={<AddIcon />}
                        bg="purple.900"
                        color="white"
                        _hover={{ bg: 'purple.800' }}
                        onClick={() => handleStudentInviteClick()}
                        >
                        Invite Students
                        </Button>
                    </Flex>
                    <VStack spacing={4} align="start">
                      {studentLoading ? (
                        <Spinner size="lg" />
                      ) : students.length === 0 ? (
                        <Text>No students found.</Text>
                      ) : (
                        students.map((student) => (
                          <Flex
                            key={student._id}
                            p={4}
                            borderWidth="1px"
                            borderRadius="lg"
                            align="center"
                            width="full"
                            justify="space-between" 
                          >
                            <Flex align="center">
                              <Avatar name={student.name} src={student.photoURL} />
                              <Box ml={4}>
                                <Text fontWeight="bold">{student.name}</Text>
                                <Text>{student.email}</Text>
                              </Box>
                            </Flex>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                aria-label="Options"
                                icon={<BiDotsVerticalRounded />}
                                size={"md"}
                                bg={"white"}
                              />
                              <MenuList>
                                <MenuItem onClick={() => handleDeleteStudent(student._id)}>Delete</MenuItem>
                              </MenuList>
                            </Menu>
                          </Flex>
                        ))
                      )}
                    </VStack>

                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </TabPanel>
            <TabPanel><Chat inputFromChat={inputFromChat} clearPrefillMessage={clearPrefillMessage}/></TabPanel>
            <TabPanel><SRSHistory /></TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <Modal isOpen={isAddTeachersOpen} onClose={() => { setSelectedTeachers([]); onAddTeachersClose(); }} size="lg">
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Add Teachers to Project</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <FormControl mb={4}>
        <FormLabel>Select Teacher</FormLabel>
        <VStack align="start" spacing={4}>
          {classTeachers.map((teacher) => {
            const isTeacherInProject = projectDetails?.teacherIds.includes(teacher._id);
            if(!isTeacherInProject){
            return (
              <HStack key={teacher._id} spacing={4}>
                <Checkbox
                  checked={selectedTeachers.includes(teacher._id)}
                  disabled={isTeacherInProject}
                  onChange={() => handleTeacherSelection(teacher._id)}
                >
                  {teacher.email}
                </Checkbox>
              </HStack>
            );
            }
          })}
        </VStack>
      </FormControl>
    </ModalBody>
    <ModalFooter>
    <Button
      bg="purple.900"
      color="white"
      _hover={{ bg: 'purple.800' }}
      onClick={handleInviteTeachers}
      isDisabled={sendloading} 
    >
      {sendloading ? (
        <Spinner size="sm" color="white" mr={2} />
      ) : null}
      Add Teachers
    </Button>
      <Button marginLeft={2} bg="gray.100" color="purple.900" _hover={{ bg: 'gray.300' }} onClick={onAddTeachersClose}>
        Cancel
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>


<Modal isOpen={isAddStudentsOpen} onClose={() => { setSelectedStudents([]); onAddStudentsClose(); }} size="lg">
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Add Students to Project</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <FormControl mb={4}>
        <FormLabel>Select Student</FormLabel>
        <VStack align="start" spacing={4}>
          {classStudents.map((student) => {
            const isStudentInProject = projectDetails?.studentIds.includes(student._id);
            if(!isStudentInProject){
            return (
              <HStack key={student._id} spacing={4}>
                <Checkbox
                  checked={selectedStudents.includes(student._id)}
                  disabled={isStudentInProject}
                  onChange={() => handleStudentSelection(student._id)}
                >
                  {student.email}
                </Checkbox>
              </HStack>
            );
            }
          })}
        </VStack>
      </FormControl>
    </ModalBody>
    <ModalFooter>
    <Button
    bg="purple.900"
    color="white"
    _hover={{ bg: 'purple.800' }}
    onClick={handleInviteStudents}
    isDisabled={sendloading} 
  >
    {sendloading ? (
      <Spinner size="sm" color="white" mr={2} />
    ) : null}
    Add Students
  </Button>
      <Button marginLeft={2} bg="gray.100" color="purple.900" _hover={{ bg: 'gray.300' }} onClick={onAddStudentsClose}>
        Cancel
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>


<Modal
  isOpen={isEditProjectOpen}
  onClose={() => { onEditProjectClose(); setEditError(''); }}
>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Edit Project</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <FormControl mb={4}>
        <FormLabel>Project Name</FormLabel>
        <Input
          type="text"
          name="projectName"
          value={editProjectData.projectName}
          onChange={handleEditProjectChange}
        />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel>Project Goal</FormLabel>
        <Input
          type="text"
          name="projectGoal"
          value={editProjectData.projectGoal}
          onChange={handleEditProjectChange}
        />
      </FormControl>
      {editProjectData.projectDescription && (

<FormControl mb={4}>
<FormLabel>Project Description</FormLabel>
<Input
  type="text"
  name="projectDescription"
  value={editProjectData.projectDescription}
  onChange={handleEditProjectChange}
/>
</FormControl>
      )}

{editProjectData.projectBranding && (

<FormControl mb={4}>
<FormLabel>Project Planning</FormLabel>
<Input
  type="text"
  name="projectBranding"
  value={editProjectData.projectBranding}
  onChange={handleEditProjectChange}
/>
</FormControl>
      )}


      <FormControl mb={4}>
        <FormLabel>Chat Option</FormLabel>
        <Select
          name="chatOption"
          value={editProjectData.chatOption}
          onChange={handleDropdownChange}
        >
          <option value="Native Chat">Native Chat</option>
          <option value="Discord Server">Discord Server</option>
        </Select>
      </FormControl>

      {editProjectData.chatOption === 'Discord Server' && (
        <FormControl mb={4}>
          <FormLabel>Discord Server ID</FormLabel>
          <Input
            type="text"
            name="discordServerId"
            value={editProjectData.discordServerId}
            onChange={handleEditProjectChange}
          />
        </FormControl>
      )}

      
      {editError && (
        <Box
          color="red.500"
          bg="red.50"
          p={3}
          mb={4}
          borderRadius="md"
          border="1px"
          borderColor="red.300"
          textAlign={'center'}
        >
          {editError}
        </Box>
      )}
    </ModalBody>
    <ModalFooter>
      <Button
        bg="purple.900"
        _hover={{ bg: 'purple.800' }}
        color="white"
        mr={3}
        onClick={handleEditProjectSubmit}
      >
        Save Changes
      </Button>
      <Button onClick={() => { onEditProjectClose(); setEditError(''); }}>
        Cancel
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>

    </TDashboard>
  );
};

export default ProjectView;
