import React, { useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { motion, useScroll, useTransform } from 'framer-motion';
import BackgroundLayer from '../../Assets/Layering/BaseLayerB.png';
import BaseLayer from '../../Assets/Layering/BaseLayer3.png';

export default function MultiLayerParallax() {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });
    const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);
    const textY = useTransform(scrollYProgress, [0, 1], ["0%", "200%"]);

    return (
        <Box
            ref={ref}
            w="full"
            h="screen"
            overflow="hidden"
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="10"
        >
            <motion.div
                className="absolute inset-0 z-0"
                style={{
                    backgroundColor: 'black',
                    backgroundImage: `url('${BackgroundLayer}')`,
                    backgroundPosition: "bottom",
                    backgroundSize: "contain",
                    backgroundRepeat: 'no-repeat',
                    y: backgroundY,
                }}
            />
            <Box
                className="absolute inset-0 z-10"
                style={{
                    backgroundImage: `url(${BaseLayer})`,
                    backgroundPosition: "bottom",
                    backgroundSize: "contain",
                    backgroundRepeat: 'no-repeat'
                }}
            />
        </Box>
    );
}
