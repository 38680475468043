import { Box, Text, Image, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const GroupCard = ({ group,classId,projectId,index }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    sessionStorage.setItem("activeClass", JSON.stringify(null)); 
    sessionStorage.setItem("activeNav",JSON.stringify(null));
    sessionStorage.setItem("activeProject",JSON.stringify(null));
    sessionStorage.setItem("activeGroup",JSON.stringify(index));
    const route = "/student-portal/class/"+classId+"/project/"+projectId+"/group/"+group._id;
    navigate(route);
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      cursor="pointer"
      onClick={handleClick}
    >
      <Image src={group.url} alt={group.groupName} />
      <VStack p={4} align="start">
        <Text fontWeight="bold" fontSize="xl">{group.groupName}</Text>
        <Text>Goal: {group.groupDescription}</Text>
      </VStack>
    </Box>
  );
};

export default GroupCard;
