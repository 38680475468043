import { PUBLIC_URL } from '../../../commons/utils.tsx';
import axios from 'axios';
export const getChatGPTResponse = async (messages) => {
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/teacher/getChatGPTResponse`, { messages });
      return response.data;
    } catch (error) {
      console.error('Error getting  chatGPT message:', error);
      throw new Error('Failed to get chatGPT message:');
    }
  };

  export const getChatGPTResponseForSRS = async (messages) => {
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/student/getChatGPTResponseforsrs`, { messages });
      return response.data;
    } catch (error) {
      console.error('Error getting  chatGPT message:', error);
      throw new Error('Failed to get chatGPT message:');
    }
  };

  export const appendGPT = async (messages, tId) => {
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/teacher/storeChatGPTResponse`, { messages,tId });
      return response;
    } catch (error) {
      console.error('Error storing chatGPT message:', error);
      throw new Error('Failed to store chatGPT message');
    }
  }

  
  