import React, { useState } from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react";
import ChatGPTChat from "./ChatGPTMessage.tsx";
import StudentPrivateMessage from "./ViewStudentPrivateMessages.tsx";
import StudentProjectMessage from "./ViewStudentProjectMessages.tsx";
import ViewProjectChatHistory from "./ViewProjectChatHistory.tsx";
import ViewGroupChatHistory from "./ViewGroupChatHistory.tsx";
import TDashboard from "../Dashboard/Dashboard.tsx";

const Message = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <TDashboard>
      <Box height="90%" p={4} textColor={'purple.900'} m={16}>
        <Tabs index={selectedTab} onChange={(index) => setSelectedTab(index)}>
          <TabList mb="1em">
            <Tab>Project History</Tab>
            <Tab>Group History</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ViewProjectChatHistory />
            </TabPanel>
            <TabPanel>
              <ViewGroupChatHistory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </TDashboard>
  );
};

export default Message;
