import React, { useState, useEffect } from "react";
import { 
  Box, 
  Button, 
  FormControl, 
  FormLabel, 
  Input, 
  InputGroup, 
  InputRightElement, 
  Radio, 
  RadioGroup, 
  Stack, 
  Tabs, 
  TabPanels, 
  TabPanel, 
  Heading, 
  IconButton 
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import TDashboard from "../Dashboard/Dashboard.tsx";
import { saveConfig, getConfig } from "../../../Services/Teacher/teacherService.tsx";

const Config = () => {
  const [selectedAI, setSelectedAI] = useState("gpt");
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false); // For toggling API key visibility
  const [initialConfig, setInitialConfig] = useState({ selectedAI: "gpt", apiKey: "" }); // To track initial config
  const [isModified, setIsModified] = useState(false); // To track if the configuration has been modified
  const [apiKeys, setApiKeys] = useState({
    gpt: "",
    azureAI: "",
  }); // To store API keys for each model
  const toast = useToast();
  const teacherId = sessionStorage.getItem("CurrentLoggedInTId");

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await getConfig(teacherId);
        if (config) {
          setSelectedAI(config.selectedAI);
          
          const newApiKeys = { 
            gpt: config.selectedAI === "gpt" ? config.key : "", 
            azureAI: config.selectedAI === "azureAI" ? config.key : ""
          };
          setApiKeys(newApiKeys);
          setApiKey(newApiKeys[config.selectedAI] || "");  
          setInitialConfig({ selectedAI: config.selectedAI, apiKey: config.key });
        }
      } catch (error) {
        console.error("Error loading configuration:", error);
        toast({
          title: "Error",
          description: "Failed to load configuration.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    fetchConfig();
  }, [teacherId, toast]);
  
  // Update isModified when configuration changes
  useEffect(() => {
    if (selectedAI !== initialConfig.selectedAI || apiKey !== initialConfig.apiKey) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [selectedAI, apiKey, initialConfig]);

  const handleSave = async () => {
    if (!apiKey) {
      toast({
        title: "API Key Required",
        description: "Please enter an API key.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      const configData = { teacherId, selectedAI, key: apiKey };
      await saveConfig(configData);

      toast({
        title: "Configuration Saved",
        description: `You have selected ${selectedAI} with your API key.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Store the new API key for the selected AI model
      setApiKeys((prevKeys) => {
        const newKeys = { ...prevKeys };
        if (selectedAI === "gpt") {
          delete newKeys.azureAI; 
        } else if (selectedAI === "azureAI") {
          delete newKeys.gpt; 
        }
        newKeys[selectedAI] = apiKey; 
        return newKeys;
      });

      setInitialConfig({ selectedAI, apiKey }); 
    } catch (error) {
      console.error("Error saving configuration:", error);
      toast({
        title: "Error",
        description: "Failed to save configuration.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAIChange = (value) => {
    setSelectedAI(value);
    setApiKey(apiKeys[value] || ""); 
  };

  return (
    <TDashboard>
      <Box p={8} width="60%" mx="-2"  mt={12}>
        <Tabs variant="soft-rounded" colorScheme="purple">
          <TabPanels>
            <TabPanel>
              <FormControl id="aiSelection" mt={4}>
                <FormLabel>Select AI Model</FormLabel>
                <RadioGroup onChange={handleAIChange} value={selectedAI}>
                  <Stack direction="row" spacing={5}>
                    <Radio value="gpt">GPT</Radio>
                    <Radio value="azureAI">Azure AI</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>

              <FormControl id="apiKey" mt={4} isRequired>
                <FormLabel>API Key</FormLabel>
                <InputGroup>
                  <Input
                    type={showApiKey ? "text" : "password"} 
                    placeholder="Enter your API key here"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    focusBorderColor="purple.500"
                  />
                  <InputRightElement>
                    <IconButton
                      icon={showApiKey ? <ViewOffIcon /> : <ViewIcon />}
                      onClick={() => setShowApiKey(!showApiKey)}
                      variant="ghost"
                      aria-label="Toggle API key visibility"
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button
                mt={10}
                bg="purple.900"
                color="white"
                _hover={{ bg: "purple.900" }}
                onClick={handleSave}
                isLoading={loading}
                loadingText="Saving"
                isFullWidth
                isDisabled={!isModified || !selectedAI || !apiKey} 
              >
                Save Configuration
              </Button>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </TDashboard>
  );
};

export default Config;
