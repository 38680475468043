import React, { useState, useEffect } from 'react';
import { getQuestionsByTeacherId } from '../../../Services/Teacher/teacherService.tsx';
import {
  Box,
  useToast,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Text,
  Select,
} from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/react';
import { FiFilter } from 'react-icons/fi';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { DatePicker } from 'antd';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';


const QuestionsTable = () => {
  const userId = sessionStorage.getItem('CurrentLoggedInTId').trim();
  const toast = useToast();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsPerPage] = useState(5);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false); // Renamed state for question modal
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false); // State for filter modal
  const [searchQuery, setSearchQuery] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [isCorrectFilter, setIsCorrectFilter] = useState('');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [tempIsCorrectFilter, setTempIsCorrectFilter] = useState('');


  

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const questionsData = await getQuestionsByTeacherId(userId);
        if (!Array.isArray(questionsData)) 
        return;
        setQuestions(questionsData || []);
        setFilteredQuestions(questionsData || []);
      } catch (error) {
        toast({
          title: 'Error fetching questions',
          description: 'There was an error fetching your questions. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [userId, toast]);

  
  useEffect(() => {
    const filtered = questions.filter((question) => {
      const isInDateRange = (dateRange[0] && dateRange[1])
        ? new Date(question.createdAt) >= dateRange[0] && new Date(question.createdAt) <= dateRange[1]
        : true;
  
      const queryLower = searchQuery.toLowerCase();
      const matchesSearch = question.studentId.name.toLowerCase().includes(queryLower) || // Filter by student name
        question.questionData.question.toLowerCase().includes(queryLower) ||
        question.questionData.topic.toLowerCase().includes(queryLower) ||
        question.questionData.options.some(option => option.toLowerCase().includes(queryLower));
  
      const matchesIsCorrect = isCorrectFilter
        ? (isCorrectFilter === 'Yes' ? question.questionData.isCorrect : !question.questionData.isCorrect)
        : true;
  
      const matchesStudents = selectedStudents.length > 0
        ? selectedStudents.includes(question.studentId.name)
        : true;
  
      return isInDateRange && matchesSearch && matchesIsCorrect && matchesStudents;
    });
  
    // Sorting
    if (sortConfig.key) {
      filtered.sort((a, b) => {
        const aValue = a.questionData[sortConfig.key];
        const bValue = b.questionData[sortConfig.key];
        if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
  
    setFilteredQuestions(filtered);
  }, [searchQuery, dateRange, questions, sortConfig, isCorrectFilter, selectedStudents]);
  

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = filteredQuestions.slice(indexOfFirstQuestion, indexOfLastQuestion);

  const paginate = (direction) => {
    setCurrentPage((prevPage) => direction === 'next' ? prevPage + 1 : prevPage - 1);
  };

  const openQuestionModal = (question) => { // Renamed function for opening question modal
    setSelectedQuestion(question);
    setIsQuestionModalOpen(true);
  };

  const closeQuestionModal = () => { // Renamed function for closing question modal
    setIsQuestionModalOpen(false);
    setSelectedQuestion(null);
  };

  const openFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const renderContent = (content) => {
    const codeBlockRegex = /```([a-zA-Z]*)\n?([\s\S]*?)```|```([^`]+)```/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = codeBlockRegex.exec(content)) !== null) {
      const [fullMatch, language, code] = match;
      const startIndex = match.index;

      if (startIndex > lastIndex) {
        parts.push(<Text key={`text-${lastIndex}`}>{content.slice(lastIndex, startIndex)}</Text>);
      }

      parts.push(
        <SyntaxHighlighter key={`code-${startIndex}`} language={language || 'javascript'} style={materialDark}>
          {code.trim()}
        </SyntaxHighlighter>
      );
      lastIndex = codeBlockRegex.lastIndex;
    }

    if (lastIndex < content.length) {
      parts.push(<Text key={`text-${lastIndex}`}>{content.slice(lastIndex)}</Text>);
    }

    return parts;
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleIsCorrectFilterChange = (event) => {
    setTempIsCorrectFilter(event.target.value); 
    setCurrentPage(1);
};


  const totalPages = Math.ceil(filteredQuestions.length / questionsPerPage);
  const applyFilters = () => {
    const filteredQuestions = questions.filter((question) => {
        
        const matchesIsCorrect = tempIsCorrectFilter
            ? (tempIsCorrectFilter === 'Yes' ? question.questionData.isCorrect : !question.questionData.isCorrect)
            : true;

        const matchesStudents = selectedStudents.length > 0
            ? selectedStudents.includes(question.studentId.name)
            : true;

        return matchesIsCorrect && matchesStudents;
    });

    setFilteredQuestions(filteredQuestions);
    closeFilterModal(); 
};

const clearFilters = () => {
    setSelectedStudents([]); 
    setTempIsCorrectFilter(''); 
    setIsCorrectFilter(''); 
    setFilteredQuestions(questions); 
    closeFilterModal();
};


  
  

  return (
    <>
      
      <Flex mb={4} align="center" justify="space-between">
      <IconButton
        icon={<FiFilter />}
        aria-label="Filter Questions"
        onClick={openFilterModal} 
        mb={4}
        variant={'ghost'}
      />
        <DatePicker.RangePicker
          onChange={(dates) => setDateRange(dates ? [dates[0].toDate(), dates[1].toDate()] : [null, null])}
          format="YYYY-MM-DD"
          style={{ width: '40%' }}
        />
        <InputGroup width="40%">
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
          <Input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
          />
          {searchQuery && (
            <InputRightElement
              cursor="pointer"
              children={<CloseIcon fontSize={14} _hover={{ color: "gray.600" }} color="gray.300" />}
              onClick={() => setSearchQuery('')}
            />
          )}
        </InputGroup>
      </Flex>
      <Box p={6} mx="auto" mt={4} borderWidth="1px" borderRadius="lg" boxShadow="lg">
        <Heading fontSize="lg" mb={4}>Answered Questions</Heading>

        {loading ? (
          <Flex justify="center" align="center" height="100%">
            <Spinner />
          </Flex>
        ) : (
          <>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th onClick={() => handleSort('name')} cursor="pointer">Name</Th>
                  <Th onClick={() => handleSort('topic')} cursor="pointer">Topic</Th>
                  <Th onClick={() => handleSort('question')} cursor="pointer">Question</Th>
                  <Th onClick={() => handleSort('correct_answer')} cursor="pointer">Correct Answer</Th>
                  <Th onClick={() => handleSort('selectedOption')} cursor="pointer">Selected Option</Th>
                  <Th onClick={() => handleSort('isCorrect')} cursor="pointer">Is Correct?</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentQuestions.length > 0 ? (
                  currentQuestions.map((question, index) => (
                    <Tr key={index} onClick={() => openQuestionModal(question)} style={{ cursor: 'pointer' }}>
                      <Td>{question.studentId.name}</Td>
                      <Td>{question.questionData.topic}</Td>
                      <Td>{question.questionData.question}</Td>
                      <Td>{question.questionData.correct_answer}</Td>
                      <Td>{question.questionData.selectedOption}</Td>
                      <Td>{question.questionData.isCorrect ? 'Yes' : 'No'}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan="5" textAlign="center">No questions found.</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>

            <Flex mt={4} justify="space-between">
              <Button
                onClick={() => paginate('prev')}
                isDisabled={currentPage === 1}
              >
                <FiChevronLeft /> Previous
              </Button>
              <Text>Page {currentPage}</Text>
              <Button
                onClick={() => paginate('next')}
                isDisabled={currentQuestions.length < questionsPerPage}
              >
                Next <FiChevronRight />
              </Button>
            </Flex>
            {/* Modal for Question Details */}
            <Modal isOpen={isQuestionModalOpen} onClose={closeQuestionModal}> 
              <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(2px)" />
              <ModalContent maxWidth="600px" maxHeight="600px">
                <ModalHeader>Question Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody maxHeight="600px" overflowY="auto">
                  {selectedQuestion && (
                    <>
                      <Box mb={4}><strong>Question:</strong> {renderContent(selectedQuestion.questionData.question)}</Box>
                      <Box mb={4}><strong>Options:</strong></Box>
                      <Box pl={4}>
                        {selectedQuestion.questionData.options.map((option, index) => (
                          <Box key={index} bg={option === selectedQuestion.questionData.correct_answer && selectedQuestion.questionData.isCorrect ? 'green.100' : option === selectedQuestion.questionData.selectedOption && !selectedQuestion.questionData.isCorrect ? 'red.100' : 'transparent'} p={2} borderRadius="md">
                            {renderContent(option)}
                          </Box>
                        ))}
                      </Box>
                      <Box mt={4}><strong>Correct Answer:</strong> {renderContent(selectedQuestion.questionData.correct_answer)}</Box>
                      <Box><strong>Your Selected Option:</strong> {renderContent(selectedQuestion.questionData.selectedOption)}</Box>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button bg={'purple.900'} color={'white'} _hover={{ bg: 'purple.900' }} onClick={closeQuestionModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for Filter Selection */}
            <Modal isOpen={isFilterModalOpen} onClose={closeFilterModal}> 
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Filter Questions</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
    <Text mb={2}>Filter by Student Name:</Text>
    <Box maxHeight="150px" overflowY="auto" borderWidth="1px" borderRadius="md" padding="2">
        <CheckboxGroup value={selectedStudents} onChange={(value) => setSelectedStudents(value)}>
            {Array.from(new Set(questions.map((q) => q.studentId.name))).map((studentName) => (
                <Box key={studentName} mb={2}>
                    <Checkbox value={studentName}>
                        {studentName}
                    </Checkbox>
                </Box>
            ))}
        </CheckboxGroup>
    </Box>

    <Text mt={4} mb={2}>Filter by Is Correct:</Text>
    <Box mb={2}>
        <Select 
            placeholder="Select option" 
            onChange={handleIsCorrectFilterChange} 
            value={tempIsCorrectFilter} // Use temporary state here
        >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
        </Select>
    </Box>
</ModalBody>


                <ModalFooter>
                <Button onClick={applyFilters} color={'white'} bg={'purple.900'} _hover={{bg:'purple.900'}}>Apply Filters</Button>
                <Button onClick={clearFilters} ml={3}>Clear Filters</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

          </>
        )}
      </Box>
    </>
  );
};

export default QuestionsTable;
