import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Box,
  Text,
  Alert,
  AlertIcon,
  Fade,
  Select,
  Spinner
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse'
import { addStudentsToClass, createClass, createStudents } from '../../../Services/Teacher/teacherService.tsx'; // Import the service function

const CreateClass = ({ isOpen, onClose }) => {
  const [gradeLevel, setGradeLevel] = useState('');
  const [className, setClassName] = useState('');
  const [academicYear, setAcademicYear] = useState('');
  const [academicSemester, setAcademicSemester] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv']
    },
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      csvParser(acceptedFiles[0]);
    }
  });

  const imageUrls = [
    "https://gstatic.com/classroom/themes/img_breakfast.jpg",
"https://gstatic.com/classroom/themes/Honors.jpg",
"https://gstatic.com/classroom/themes/img_graduation.jpg",
"https://gstatic.com/classroom/themes/img_bookclub.jpg",
"https://gstatic.com/classroom/themes/img_code.jpg",
"https://gstatic.com/classroom/themes/img_reachout.jpg",
"https://gstatic.com/classroom/themes/img_learnlanguage.jpg",
"https://gstatic.com/classroom/themes/img_backtoschool.jpg",
"https://gstatic.com/classroom/themes/img_read_.jpg",
"https://gstatic.com/classroom/themes/WorldStudies.jpg",
"https://gstatic.com/classroom/themes/English.jpg",
"https://gstatic.com/classroom/themes/WorldHistory.jpg",
"https://gstatic.com/classroom/themes/SocialStudies.jpg",
"https://gstatic.com/classroom/themes/Geography.jpg",
"https://gstatic.com/classroom/themes/USHistory.jpg",
"https://gstatic.com/classroom/themes/Writing.jpg",
"https://gstatic.com/classroom/themes/LanguageArts.jpg",
"https://gstatic.com/classroom/themes/Geometry.jpg",
"https://gstatic.com/classroom/themes/Psychology.jpg",
"https://gstatic.com/classroom/themes/Math.jpg",
"https://gstatic.com/classroom/themes/Chemistry.jpg",
"https://gstatic.com/classroom/themes/Physics.jpg",
"https://gstatic.com/classroom/themes/Biology.jpg",
"https://gstatic.com/classroom/themes/img_coffee.jpg",
"https://gstatic.com/classroom/themes/img_cinema.jpg",
"https://gstatic.com/classroom/themes/img_violin2.jpg",
"https://gstatic.com/classroom/themes/img_arts.jpg",
"https://gstatic.com/classroom/themes/img_theatreopera.jpg",
"https://gstatic.com/classroom/themes/img_mealfamily.jpg",
"https://gstatic.com/classroom/themes/img_learninstrument.jpg",
"https://gstatic.com/classroom/themes/Design.jpg",
"https://gstatic.com/classroom/themes/img_concert.jpg",
"https://gstatic.com/classroom/themes/img_gamenight.jpg",
"https://gstatic.com/classroom/themes/img_handcraft.jpg",
"https://gstatic.com/classroom/themes/img_camping.jpg",
"https://gstatic.com/classroom/themes/img_hiking.jpg",
"https://gstatic.com/classroom/themes/img_hobby.jpg",
"https://gstatic.com/classroom/themes/img_sailing.jpg",
"https://gstatic.com/classroom/themes/img_videogaming.jpg",
"https://gstatic.com/classroom/themes/img_carmaintenance.jpg",

  ];
  

  const getRandomImageUrl = () => {
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    return imageUrls[randomIndex];
  };

  const [parsedData,setParsedData] = useState(null);

  const getEmails = () => {
    if (parsedData) {
      return parsedData.map(row => {
        if (row['Email Address']) {
          return row['Email Address'];
        }
        return null;
      }).filter(entry => entry !== null);
    }
    return [];
  };

  

  const getNames = () => {
    if (parsedData) {
      return parsedData.map(row => {
        if (row['Email Address']) {
          return row['First Name'] + ' ' + row['Last Name'];
        }
        return null;
      }).filter(entry => entry !== null);
    }
    return [];
  };

  const csvParser = (files) =>{
    console.log(files)
    Papa.parse(files,{
      complete:function(results){
          console.log(results.data)
          setParsedData(results.data);
      },
      header:true, 
      skipEmptyLines:true,
    })
  };

  const handleSubmit = async () => {

      sessionStorage.setItem("activeNav", JSON.stringify(0));
    if (!gradeLevel || !className || !academicYear || !academicSemester) {
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
      return;
    }

    let names;
    let mails;
    
    const selectedImageUrl = getRandomImageUrl();
    setIsLoading(true);

    const currentLoggedInTId = sessionStorage.getItem("CurrentLoggedInTId");

    if(file)
    {
        names = JSON.stringify(getNames());
        mails = JSON.stringify(getEmails());
        names = JSON.parse(names);
        mails = JSON.parse(mails);
        try{
            const studentIds = await createStudents(mails);
            const cls = await createClass({
                gradeLevel,
                className,
                academicYear,
                academicSemester,
                url: selectedImageUrl,
                currentUserId: currentLoggedInTId?.toString() || ""
              });
              await addStudentsToClass(cls._id,studentIds);
              handleClose();
              if(window!==undefined){
                window.location.reload();
                }
            
        }
        catch (error) {
            console.error('Error creating class:', error);
          } finally {
            setIsLoading(false);
          }
    }
    else
    {
    try {
      const cls = await createClass({
        gradeLevel,
        className,
        academicYear,
        academicSemester,
        url: selectedImageUrl,
        currentUserId: currentLoggedInTId?.toString() || ""
      });
      handleClose();
      if(window!==undefined){
        window.location.reload();
      }

    } catch (error) {
      console.error('Error creating class:', error);
    } finally {
      setIsLoading(false);
    }
}
  };

  const handleClose = () => {
    setGradeLevel('');
    setClassName('');
    setAcademicYear('');
    setAcademicSemester('');
    setFile(null);
    onClose();
  };

  const currentYear = new Date().getFullYear();
  const academicYears = [currentYear, currentYear + 1, currentYear + 2];
  const semesters = ["Spring", "Summer", "Fall", "Winter"];

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a Class</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {showError && (
            <Fade in={showError}>
              <Alert status="error" mb={4}>
                <AlertIcon />
                All fields are required.
              </Alert>
            </Fade>
          )}
          <VStack spacing={4} align="stretch">
            <FormControl isRequired>
              <FormLabel>Grade Level</FormLabel>
              <Input
                value={gradeLevel}
                onChange={(e) => setGradeLevel(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Class Name</FormLabel>
              <Input
                value={className}
                onChange={(e) => setClassName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Academic Year</FormLabel>
              <Select
                placeholder="Select academic year"
                value={academicYear}
                onChange={(e) => setAcademicYear(e.target.value)}
              >
                {academicYears.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Academic Semester</FormLabel>
              <Select
                placeholder="Select academic semester"
                value={academicSemester}
                onChange={(e) => setAcademicSemester(e.target.value)}
              >
                {semesters.map(semester => (
                  <option key={semester} value={semester}>{semester}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Attachment (CSV)</FormLabel>
              <Box {...getRootProps()} border="1px" borderColor="gray.300" p={4} textAlign="center">
                <input {...getInputProps()} />
                {file ? (
                  <Text>{file.name}</Text>
                ) : (
                  <Text cursor={'pointer'}>Drag 'n' drop a CSV file here, or click to select one</Text>
                )}
              </Box>
            </FormControl>
            <Button bg="purple.900" _hover={'purple.900'} color="white" onClick={handleSubmit} isDisabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Submit'}
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateClass;
