import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  CloseButton,
  useColorModeValue,
  Icon,
  Collapse,
  Avatar,
} from "@chakra-ui/react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  FiHome,
  FiMessageSquare,
  FiChevronDown,
  FiChevronUp,
  FiBookOpen,
  FiBook,
  FiFolder,
  FiSettings,
} from "react-icons/fi";
import { SiGoogleclassroom } from "react-icons/si";
import { GoProjectRoadmap} from "react-icons/go";
import { LuHistory } from "react-icons/lu";
import {TiGroupOutline}  from 'react-icons/ti';
import useDrivePicker from 'google-drive-picker';
import CreateClass from "../teacher-portal/CreateClass/CreateClass.tsx";
import CreateProject from "../teacher-portal/CreateProject/CreateProject.tsx";
import {  getClassesByUserId, getProjectsByIds } from "../../Services/Teacher/teacherService.tsx";
import { parse } from "path";
import CreateGroup from "../teacher-portal/CreateGroup/CreateGroup.tsx";
import GroupView from "../teacher-portal/GroupView/GroupView.tsx";
import { GiSupersonicArrow } from "react-icons/gi";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { app } from "../../config.js"; 
import giimage from '../../Assets/G-Ideas.png'

const SideNav = ({ onClose, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const safeJsonParse = (key, defaultValue) => {
    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch {
      return defaultValue;
    }
  };

  // Initial state setup
  const [activeNav, setActiveNav] = useState(() => safeJsonParse("activeNav", 0));
  const [activeClass, setActiveClass] = useState(() => safeJsonParse("activeClass", null));
  const [activeProject, setActiveProject] = useState(() => safeJsonParse("activeProject", null));
  const [activeGroup, setActiveGroup] = useState(() => safeJsonParse("activeGroup", null));
  const [navItems, setNavItems] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classData, setClassData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [showCreateClassModal, setShowCreateClassModal] = useState<boolean>(false);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState<boolean>(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(() => safeJsonParse("isDropdownOpen", false));
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(() => safeJsonParse("isProjectDropdownOpen", false));
  const [projects, setProjects] = useState({});
  const [groups, setGroups] = useState({});
  const [openProjects, setOpenProjects] = useState(() => safeJsonParse("openProjects", {}));
  const [openGroups, setOpenGroups] = useState(() => safeJsonParse("openGroups", {}));
  const userType = sessionStorage.getItem("userType");
  const { classId, projectId, groupId } = useParams();

  const TeacherLinks = [
    { name: "Home", icon: FiHome, path: "/teacher-portal/homeframe" },
    { name: "Create Class", icon: FiBook },
    { name: "Your Classes", icon: SiGoogleclassroom },
    { name: "Chat History", icon: FiMessageSquare, path: "/teacher-portal/messaging" },
    {name: "ChatGPT History", icon: LuHistory, path: "/teacher-portal/gpthistory"},
    { name: "Google Drive", icon: FiFolder },
    {name: "LearnQ", icon: GiSupersonicArrow, path: "/teacher-portal/learnq"},
    {name: "Config", icon:FiSettings, path:"/teacher-portal/config"},
  ];

  const TeacherProjectLinks = [
    { name: "Home", icon: FiHome, path: "/teacher-portal/homeframe" },
    { name: "Create Project", icon: FiBook },
    { name: "Your Classes", icon: SiGoogleclassroom },
    { name: "Chat History", icon: FiMessageSquare, path: "/teacher-portal/messaging" },
    {name: "ChatGPT History", icon: LuHistory, path: "/teacher-portal/gpthistory"},
    { name: "Google Drive", icon: FiFolder },
    {name: "LearnQ", icon: GiSupersonicArrow, path: "/teacher-portal/learnq"},
    {name: "Config", icon:FiSettings, path:"/teacher-portal/config"},
  ];

  const TeacherGroupLinks = [
    { name: "Home", icon: FiHome, path: "/teacher-portal/homeframe" },
    { name: "Create Group", icon: FiBook },
    { name: "Your Classes", icon: SiGoogleclassroom },
    { name: "Chat History", icon: FiMessageSquare, path: "/teacher-portal/messaging" },
    {name: "ChatGPT History", icon: LuHistory, path: "/teacher-portal/gpthistory"},
    { name: "Google Drive", icon: FiFolder },
    {name: "LearnQ", icon: GiSupersonicArrow, path: "/teacher-portal/learnq"},
    {name: "Config", icon:FiSettings, path:"/teacher-portal/config"},
  ];

  const UserItems = [
    { name: "Home", icon: FiHome, path: "/student-portal/homeframe" },
    { name: "Your Classes", icon: SiGoogleclassroom },
    {name: "Chat History", icon: FiMessageSquare, path: "/student-portal/messaging"},
    {name: "ChatGPT History", icon: LuHistory, path: "/student-portal/gpthistory"},
    { name: "Google Drive", icon: FiFolder },
    {name: "LearnQ", icon: GiSupersonicArrow, path: "/student-portal/learnq"},
  ];

  const user = sessionStorage.getItem('userType');
      const userId = user === 'student'
          ? sessionStorage.getItem('CurrentLoggedInSId').trim()
          : sessionStorage.getItem('CurrentLoggedInTId').trim();

  useEffect(() => {
    const fetchClasses = async () => {
      setActiveNav(0);
      
      try {

        const classesData = await getClassesByUserId(userId,user);
        if(!classesData.message)
        setClasses(classesData);
        else
        setClasses([]);
        const nav = user === 'teacher' ? TeacherLinks : UserItems;
        setNavItems(nav);
  
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };
  
    fetchClasses();
  }, []);

  const currentPath = location.pathname;

  useEffect(() => {
    
  
    const an = sessionStorage.getItem("activeNav");
    const ac = sessionStorage.getItem("activeClass");
    const ap = sessionStorage.getItem("activeProject");
    const ag = sessionStorage.getItem("activeGroup");
    setActiveNav(parseInt(an));
    setActiveClass(parseInt(ac));
    setActiveProject(parseInt(ap));
    setActiveGroup(parseInt(ag));
  
    if (currentPath.includes('/class') && !currentPath.includes('/project') && userType === "teacher") {
      setNavItems(TeacherProjectLinks);
      setIsOnProject(true);
    } else if (currentPath.includes('/project') && userType === "teacher") {
      setNavItems(TeacherGroupLinks);
      setIsOnGroup(true);
    }
  
    if (currentPath.includes('/class/')) {
      const [classId, projectIdAndGroup] = currentPath.split('/class/')[1].split('/project/');
      const [projectId, groupId] = projectIdAndGroup ? projectIdAndGroup.split('/group/') : [];
  
      setIsDropdownOpen(true);
  
      setOpenProjects(prev => {
        const newOpenProjects = {
          [classId]: { [projectId]: true }
        };
        sessionStorage.setItem("openProjects", JSON.stringify(newOpenProjects));
        return newOpenProjects;
      });
  
      if (!projects[classId]) {
        const classData = classes.find(c => c._id === classId);
        setClassData(classData);
        if (classData) {
          getProjectsByIds(classData.projects)
            .then(fetchedProjects => {
              if (userType === "student") {
                fetchedProjects = fetchedProjects.filter(x => x.studentIds.includes(userId));
              } else if (userType === "teacher") {
                fetchedProjects = fetchedProjects.filter(x => x.teacherIds.includes(userId));
              }
              setProjects(prev => ({ ...prev, [classId]: fetchedProjects }));
  
              if (projectId) {
                const projectData = fetchedProjects.find(project => project._id === projectId);
                setProjectData(projectData);
                if (!openGroups[projectId]) {
                  let fetchedGroups = projectData.groups;
                  if (userType === "student") {
                    fetchedGroups = fetchedGroups.filter(x => x.studentIds.includes(userId));
                  } else if (userType === "teacher") {
                    fetchedGroups = fetchedGroups.filter(x => x.teacherIds.includes(userId));
                  }
                  setGroups(prev => ({ ...prev, [projectId]: fetchedGroups }));
                }
              }
            })
            .catch(error => {
              console.error('Error fetching projects:', error);
            });
        }
      }
    } else {
      setIsProjectDropdownOpen(false);
      setIsDropdownOpen(false);
      setOpenProjects({});
      setOpenGroups({});
      sessionStorage.setItem("isDropdownOpen", JSON.stringify(false));
      sessionStorage.setItem("openProjects", JSON.stringify({}));
      sessionStorage.setItem("isProjectDropdownOpen", JSON.stringify(false));
      sessionStorage.setItem("openGroups", JSON.stringify({}));
    }
  }, [location.pathname, classes, projects]);

  const [openPicker] = useDrivePicker();

    
  const handleOpenPicker = async() => {
    let token;
    const auth = getAuth(app);
    
    let studentTokenExpirationTime;
    let TokenExpirationTime;
    let expirationTime;

    if(userType == "student"){
      token = sessionStorage.getItem("studentGoogleAccessToken");
      studentTokenExpirationTime = sessionStorage.getItem("studentTokenExpirationTime");
      expirationTime = new Date(studentTokenExpirationTime).getTime();
    }
    else if(userType == "teacher"){
      TokenExpirationTime = sessionStorage.getItem("TokenExpirationTime");
      expirationTime = new Date(TokenExpirationTime).getTime();
      token = sessionStorage.getItem("googleAccessToken");
    }
   
    if (!token) {
      console.error("No Google Access Token found");
      return;
    }


    const currentTime = Date.now(); 
    
    const timeLeft = expirationTime - currentTime;

    console.log(`Access token expires in ${timeLeft / 1000} seconds`);

    if (timeLeft < 5 * 60 * 1000 && user) {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      provider.addScope("https://www.googleapis.com/auth/drive");
      provider.addScope("https://www.googleapis.com/auth/drive.file");
      provider.addScope("https://www.googleapis.com/auth/documents");
      const result = await signInWithPopup(auth, provider);
      const loggedInUser = result.user;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      token = credential.accessToken;
      const accessToken = await loggedInUser.getIdTokenResult(true);
      if(userType == "student"){
      sessionStorage.setItem("studentGoogleAccessToken", token);
      sessionStorage.setItem("studentTokenExpirationTime", accessToken.expirationTime);
      }
      else if(userType == "teacher"){
        sessionStorage.setItem("googleAccessToken", token);
        sessionStorage.setItem("TokenExpirationTime", accessToken.expirationTime);
      }
    }
    
    

    const customViewShared = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS)
      .setOwnedByMe(false) 
      .setIncludeFolders(true); 
    const customViewOwned = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS)
      .setOwnedByMe(true) 
      .setIncludeFolders(true); 

    openPicker({
      clientId: process.env.REACT_APP_DRIVE_CLIENTID,
      developerKey: process.env.REACT_APP_DRIVE_DEVELOPERKEY,
      token: token,
      disableDefaultView:true,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      customViews: [customViewShared, customViewOwned],
      callbackFunction: (data) => {
        if (data.action === "picked") {
          const doc = data.docs[0];
          const docUrl = doc.embedUrl || doc.url; 
    
          
          sessionStorage.setItem("selectedDoc", docUrl);
          sessionStorage.setItem("selectedDocId", doc.id);
    
          
          window.open(docUrl, '_blank');
        }
        
      },
    });
    
  };

  const [isOnProject,setIsOnProject] = useState(false);
  const [isOnGroup,setIsOnGroup] = useState(false);

  const handleNavItemClick = (index, path) => {

if(userType == "teacher"){
    if(index === 1 && isOnProject === false && isOnGroup == false){
      setShowCreateClassModal(true);
    }
    if(index === 1 && isOnProject === true && isOnGroup == false){
      setShowCreateProjectModal(true);
    }
    if(index === 1 && isOnGroup == true){
      setShowCreateGroupModal(true);
    }
    if (index === 2) { 
      setIsDropdownOpen(prev => {
        const newState = !prev;
        sessionStorage.setItem("isDropdownOpen", JSON.stringify(newState));
        return newState;
      });
      setActiveNav(2); 
      setActiveClass(null); 
      sessionStorage.setItem("activeNav", JSON.stringify(2));
      sessionStorage.setItem("activeClass", JSON.stringify(null));
    } else {
      setIsDropdownOpen(false);
      setActiveNav(index);
      setActiveClass(null); 
      sessionStorage.setItem("activeNav", JSON.stringify(index));
    }
    if (path) {
      navigate(path);
    }
    if(index === 5){
      handleOpenPicker();
    }
  }
  
  else if(userType === "student"){
    if (index === 1) { 
      setIsDropdownOpen(prev => {
        const newState = !prev;
        sessionStorage.setItem("isDropdownOpen", JSON.stringify(newState));
        return newState;
      });
      setActiveNav(1); 
      setActiveClass(null); 
      sessionStorage.setItem("activeNav", JSON.stringify(2));
      sessionStorage.setItem("activeClass", JSON.stringify(null));
    } else {
      setIsDropdownOpen(false);
      setActiveNav(index);
      setActiveClass(null); 
      sessionStorage.setItem("activeNav", JSON.stringify(index));
      sessionStorage.setItem("activeClass", JSON.stringify(null));
    }
    if (path) {
      navigate(path);
    }
    if(index === 4){
      handleOpenPicker();
    }
  }
  };
  const [isClassOpen, setIsClassOpen] = useState(false);
  const [isProjectOpen, setIsProjectOpen] =  useState(false);

  const handleClassClick = async (classId, classIndex, projectsIds) => {
    const selectedClass = classes.find(c => c._id === classId);
    setClassData(selectedClass);
    setIsClassOpen(!isClassOpen);
    const path = userType === "teacher"
      ? `/teacher-portal/class/${classId}`
      : `/student-portal/class/${classId}`;
    navigate(path);

    sessionStorage.setItem("activeNav", JSON.stringify(null));
    sessionStorage.setItem("DummyClassIndex",JSON.stringify(classIndex));
    sessionStorage.setItem("activeClass", JSON.stringify(classIndex));
    sessionStorage.setItem("activeProject", JSON.stringify(null));
    setActiveNav(null);
    setActiveClass(classIndex); 
    setActiveProject(null); 
    if(isClassOpen === true){
    setOpenProjects(prev => {
      const newOpenProjects = { ...prev, [classId]: true }; 
      sessionStorage.setItem("openProjects", JSON.stringify(newOpenProjects));
      return newOpenProjects;
    });
  }
  else{
    setOpenProjects({});
    sessionStorage.setItem("openProjects", JSON.stringify({}));
  }

    if (!projects[classId]) {
      try {
        let fetchedProjects = await getProjectsByIds(projectsIds);
        if(userType == "student"){
          fetchedProjects = fetchedProjects.filter(x=>x.studentIds.includes(userId));
        }
        if(userType == "teacher"){
          fetchedProjects = fetchedProjects.filter(x=>x.teacherIds.includes(userId));
        }
        setProjects(prev => ({ ...prev, [classId]: fetchedProjects }));
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    }
  };

  const handleProjectClick = async (classId, projectIndex, projectId, groups) => {
    const userType = sessionStorage.getItem("userType");
  
    // Update the active states
    sessionStorage.setItem("activeNav", JSON.stringify(null));
    sessionStorage.setItem("activeClass", JSON.stringify(null));
    sessionStorage.setItem("activeProject", JSON.stringify(projectIndex));
    sessionStorage.setItem("activeGroup", JSON.stringify(null));
  
    // Check if the project is already open, if yes, close it; if no, open it and close others
    setOpenProjects(prev => {
      const isCurrentlyOpen = prev[classId]?.[projectId];
      const newOpenProjects = {
        ...prev,
        [classId]: { [projectId]: !isCurrentlyOpen } // Toggle the project
      };
      sessionStorage.setItem("openProjects", JSON.stringify(newOpenProjects));
      return newOpenProjects;
    });
  
    // Fetch groups only if not already fetched
    if (!openGroups[projectId]) {
      try {
        let fetchedGroups = groups; // Assuming `groups` is passed directly here
        if (userType === "student") {
          fetchedGroups = fetchedGroups.filter(x => x.studentIds.includes(userId));
        } else if (userType === "teacher") {
          fetchedGroups = fetchedGroups.filter(x => x.teacherIds.includes(userId));
        }
        setGroups(prev => ({ ...prev, [projectId]: fetchedGroups }));
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    }
  
    // Navigate to the appropriate path
    const path = userType === "teacher"
      ? `/teacher-portal/class/${classId}/project/${projectId}`
      : `/student-portal/class/${classId}/project/${projectId}`;
    navigate(path);
    window.location.reload();
  };
  

  

  const handleGroupClick = (classId, projectId, groupIndex, groupId) => {
    // Set active group state
    sessionStorage.setItem("activeNav", JSON.stringify(null));
    sessionStorage.setItem("activeClass", JSON.stringify(null));
    sessionStorage.setItem("activeProject", JSON.stringify(null));
    sessionStorage.setItem("activeGroup", JSON.stringify(groupIndex));
  
    setActiveNav(null);
    setActiveClass(null);
    setActiveProject(null);
    setActiveGroup(groupIndex);
  
    // Ensure the project remains open
    setOpenProjects(prev => {
      const newOpenProjects = {
        ...prev,
        [classId]: { ...prev[classId], [projectId]: true }
      };
      sessionStorage.setItem("openProjects", JSON.stringify(newOpenProjects));
      return newOpenProjects;
    });
  
    const userType = sessionStorage.getItem("userType");
    const path = userType === "teacher"
      ? `/teacher-portal/class/${classId}/project/${projectId}/group/${groupId}`
      : `/student-portal/class/${classId}/project/${projectId}/group/${groupId}`;
    navigate(path);
    window.location.reload();
  };
  
  

  const handleCreateClassClose = () => {
    setShowCreateClassModal(false);
    setActiveNav(0);
    sessionStorage.setItem("activeNav", JSON.stringify(0));
  };

  const handleCreateProjectClose = () => {
    setShowCreateProjectModal(false);
    let ac = sessionStorage.getItem("DummyClassIndex");
    sessionStorage.setItem("activeClass", JSON.stringify(ac)); 
    sessionStorage.setItem("activeNav", null); 
    setActiveClass(parseInt(ac));
    setActiveNav(null);
    setIsDropdownOpen(true);
  };

  const handleCreateGroupClose = () => {
    setShowCreateGroupModal(false);
    let ac = sessionStorage.getItem("DummyClassIndex");
    sessionStorage.setItem("activeClass", JSON.stringify(ac)); 
    sessionStorage.setItem("activeNav", null); 
    setActiveClass(parseInt(ac));
    setActiveNav(null);
    setIsDropdownOpen(true);
  };
  let height = currentPath.includes('messaging') ? '118%' : '114%';
  height = currentPath.includes('gpthistory')?'136%':'114%'

  // Inside your SideNav component
return (
    <Box
        transition="3s ease"
        bg={useColorModeValue("white", "gray.900")}
        borderRight="1px"
        borderRightColor={useColorModeValue("gray.300", "gray.700")}
        w={{ base: "full", md: "16rem" }}
        pos="fixed"
        h={height}
        overflowY="auto"
        css={{
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
        }}
        {...rest}
    >
        <Flex
            h="20"
            alignItems="center"
            mx="8"
            justifyContent="space-between"
            mt="4"
        >
          <Avatar src={giimage} height={10} width={10}/>
            <Text
                fontSize="21.5px"
                fontFamily="'Great Vibes', cursive"
                fontWeight="bold"
                textColor={"purple.900"}
            >
                Generative Ideas
            </Text>
            <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
        </Flex>
        {navItems.map((link, index) => (
            <Box
                key={link.name}
                style={{ textDecoration: "none" }}
                _focus={{ boxShadow: "none" }}
                shadow={activeNav === index ? "sm" : "none"}
            >
                <Flex
                    align="center"
                    p="4"
                    mx="4"
                    mb="1"
                    borderRadius="lg"
                    role="group"
                    cursor="pointer"
                    bg={activeNav === index ? "purple.900" : "white"}
                    textColor={activeNav === index ? "white" : "purple.900"}
                    _hover={{
                        bg: "purple.900",
                        color: "white",
                    }}
                    onClick={() => handleNavItemClick(index, link.path)}
                >
                    {link.icon && (
                        <Icon
                            mr="4"
                            fontSize="20"
                            _groupHover={{
                                color: "white",
                            }}
                            as={link.icon}
                        />
                    )}
                    <Flex align="center" justify="space-between" width="full">
                        {link.name}
                    </Flex>
                </Flex>

                {link.name === "Your Classes" && (
                    <Collapse in={isDropdownOpen}>
                        {classes?.map((classData, classIndex) => (
                            <React.Fragment key={classData._id}>
                                <Box
                                    pl="8"
                                    py="2"
                                    mx="4"
                                    mb="1"
                                    borderRadius="lg"
                                    cursor="pointer"
                                    bg={activeClass === classIndex ? "purple.900" : "white"}
                                    textColor={activeClass === classIndex ? "white" : "purple.900"}
                                    _hover={{ bg: "purple.900", color: "white" }}
                                    display="flex"
                                    alignItems="center"
                                    onClick={() => handleClassClick(classData._id, classIndex, classData.projects)}
                                >
                                    <Icon as={FiBook} mr="2" />
                                    <Box
                                        flex="1"
                                        whiteSpace="nowrap"
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                    >
                                        {classData.classname}
                                    </Box>
                                </Box>
                                <Collapse in={openProjects[classData._id]}>
                                    <Box maxHeight="200px" overflowY="auto"> {/* Set a fixed height and enable scrolling */}
                                        {projects[classData._id]?.map((project, projectIndex) => (
                                            <>
                                                <Box
                                                    key={project._id}
                                                    pl="12"
                                                    py="2"
                                                    mx="4"
                                                    mb="1"
                                                    borderRadius="lg"
                                                    cursor="pointer"
                                                    _hover={{ bg: "purple.900", color: "white" }}
                                                    bg={activeProject === projectIndex ? "purple.900" : "white"}
                                                    textColor={activeProject === projectIndex ? "white" : "purple.900"}
                                                    display="flex"
                                                    alignItems="center"
                                                    onClick={() => handleProjectClick(classData._id, projectIndex, project._id, project.groups)}
                                                >
                                                    <Icon as={GoProjectRoadmap} mr="2" />
                                                    <Box
                                                        flex="1"
                                                        whiteSpace="nowrap"
                                                        overflow="hidden"
                                                        textOverflow="ellipsis"
                                                    >
                                                        {project.projectName}
                                                    </Box>
                                                </Box>
                                                <Collapse
                                                    key={project._id}
                                                    in={openProjects[classData._id] && openProjects[classData._id][project._id]}
                                                >
                                                    <Box maxHeight="150px" overflowY="auto"> {/* Set a fixed height for groups */}
                                                        {groups[project._id]?.map((group, groupIndex) => (
                                                            <Box
                                                                key={group._id}
                                                                pl="16"
                                                                py="2"
                                                                mx="4"
                                                                mb="1"
                                                                borderRadius="lg"
                                                                cursor="pointer"
                                                                _hover={{ bg: "purple.900", color: "white" }}
                                                                bg={activeGroup === groupIndex ? "purple.900" : "white"}
                                                                textColor={activeGroup === groupIndex ? "white" : "purple.900"}
                                                                display="flex"
                                                                alignItems="center"
                                                                onClick={() => handleGroupClick(classData._id, project._id, groupIndex, group._id)}
                                                            >
                                                                <Icon as={TiGroupOutline} mr="2" />
                                                                <Box
                                                                    flex="1"
                                                                    whiteSpace="nowrap"
                                                                    overflow="hidden"
                                                                    textOverflow="ellipsis"
                                                                >
                                                                    {group.groupName}
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </Collapse>
                                            </>
                                        ))}
                                    </Box>
                                </Collapse>
                            </React.Fragment>
                        ))}
                    </Collapse>
                )}
            </Box>
        ))}
        {/* Other components like CreateClass, CreateProject, CreateGroup can remain unchanged */}
        <CreateClass
            isOpen={showCreateClassModal}
            onClose={() => handleCreateClassClose()}
        />
        <CreateProject
            isOpen={showCreateProjectModal}
            onClose={() => handleCreateProjectClose()}
            classDetails={classData}
        />
        <CreateGroup
            isOpen={showCreateGroupModal}
            onClose={() => handleCreateGroupClose()}
            projectDetails={projectData}
        />
    </Box>
);

};

export default SideNav;
