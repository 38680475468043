import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Flex,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Icon,
  Text,
  Badge,
  Spinner,
  Alert,
  AlertIcon,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  CheckboxGroup,
  useDisclosure,
  Textarea,
  CloseButton,
} from "@chakra-ui/react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { FiChevronLeft, FiChevronRight,  FiDownload, FiFilter } from "react-icons/fi";
import { useAsyncDebounce } from "react-table";
import { getTeachersByIds, } from "../../../Services/Teacher/teacherService.tsx";
import { DatePicker } from 'antd';


const PrivateGPTMessages = () => {
    const [student, setStudent] = useState([]);
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sortColumn, setSortColumn] = useState('timestamp');
  const [sortDirection, setSortDirection] = useState('desc');
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedUsers, setSelectedUsers] = useState([]);
const { isOpen, onOpen, onClose } = useDisclosure();

const [selectedMessage, setSelectedMessage] = useState(null);

  const inputRef = useRef();
  
  const userType = sessionStorage.getItem("userType");
  const CurrentLoggedInTId = sessionStorage.getItem('CurrentLoggedInTId');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const user = await getTeachersByIds([CurrentLoggedInTId]);
        setStudent(user[0]);
        const allMessages = user[0].gpt;
      
      const pairedMessages = allMessages.reduce((acc, curr, index, arr) => {
        if (curr.role === 'user' && arr[index + 1]?.role === 'assistant') {
          acc.push({
            query: curr,
            response: arr[index + 1],
          });
        }
        return acc;
      }, []);
      setMessages(pairedMessages || []);
      setFilteredMessages(pairedMessages|| []);

      } catch (error) {
        console.log(error);
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [CurrentLoggedInTId]);


  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const [startDate, endDate] = dateRange;
      const filtered = messages.filter(msg => {
        const sentTime = new Date(msg.query.timestamp);
        return sentTime >= startDate && sentTime <= endDate;
      });
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages(messages);
    }
  }, [dateRange, messages]);
  

  const handleSearch = useAsyncDebounce((query) => {
    setSearchQuery(query);
    if (query) {
      const lowerQuery = query.toLowerCase();
      const filtered = messages.filter(msg => 
        msg.query.content.toLowerCase().includes(lowerQuery) ||
        msg.response.content.toLowerCase().includes(lowerQuery)
      );
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages(messages);
    }
  });

  const sortMessages = (messages, column, direction) => {
    return [...messages].sort((a, b) => {
      const aValue = a.query[column];
      const bValue = b.query[column];

      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const sortedMessages = useMemo(() => {
    return sortMessages(filteredMessages, sortColumn, sortDirection);
  }, [filteredMessages, sortColumn, sortDirection]);

  const paginatedMessages = sortedMessages.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  const handleSort = (column) => {
    setSortDirection((prevDirection) => (sortColumn === column && prevDirection === 'asc' ? 'desc' : 'asc'));
    setSortColumn(column);
  };

  const exportToCSV = () => {
    if (!filteredMessages.length) return;
    let headers;
   
     headers = ["Index","Query", "Response", "Time"];
    

    const rows = filteredMessages.map((msg, index) => {
      return [
        String(index + 1 + pageIndex * pageSize),
        String(msg.query?.content || "Unknown"),
        String(msg.response?.content || "Unknown"),
        new Date(msg.query.timestamp).toLocaleString()
      ];
    });
  
    const csvContent = [
      headers.join(","),
      ...rows.map(row => row.map(item => `"${String(item).replace(/"/g, '""')}"`).join(","))
    ].join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${student?.name}-ChatGPTmessages.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box  height={200}>
      {loading ? (
        <Spinner size="xl" />
      ) : error ? (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      ) : (
        <>
          <Flex justify="right" mb="20px">
  <Button  variant="ghost" onClick={exportToCSV} mr={4}>
    <Box as={FiDownload}  /> 
  </Button>
          </Flex>

          
            <>
            <Flex mb="20px" align="center">
      <Box mr="20px" flex="1">
        <DatePicker.RangePicker
          onChange={(dates) => setDateRange(dates ? [dates[0].toDate(), dates[1].toDate()] : [null, null])}
          format="YYYY-MM-DD"
          style={{ width: '100%' }}
        />
      </Box>
      <Box flex="2">
        <Box boxShadow="0 0 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1)" borderRadius="xl">
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
            <Input
              ref={inputRef}
              type="text"
              value={searchQuery || ""}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search..."
            />
            {searchQuery && (
              <InputRightElement
                cursor="pointer"
                children={<CloseIcon fontSize={14} _hover={{ color: "gray.600" }} color="gray.300" />}
                onClick={() => {
                  setSearchQuery("");
                  handleSearch("");
                }}
              />
            )}
          </InputGroup>
        </Box>
      </Box>
    </Flex>

              <Box 
                bg="white" 
                borderRadius="xl" 
                boxShadow="0 0 2px rgba(0, 0, 0, 0.2), 0 0 2px rgba(0, 0, 0, 0.1)"
                p="4"
              >
                <TableContainer>
                <Table variant="simple" size="md">
                <Thead>
                    <Tr>
                    <Th cursor="pointer" onClick={() => handleSort('index')} width="5%"></Th>
                    <Th cursor="pointer" onClick={() => handleSort('receiver')} width="35%">Query</Th>
                    <Th cursor="pointer" onClick={() => handleSort('message')} width="50%">Response</Th>
                    <Th cursor="pointer" onClick={() => handleSort('timestamp')} width="10%">Time</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {paginatedMessages.map((msg, index) => {
                    return (
                        <Tr key={msg._id}>
                        <Td width="5%">{index + 1 + pageIndex * pageSize}</Td>
                        <Td width="35%">
                            <Flex
                            align="center"
                            direction="column"
                            onClick={() => setSelectedMessage(msg.query.content)}
                            cursor="pointer"
                            >
                            {msg.query.content.length > 50 ? (
                                <Textarea
                                value={msg.query.content}
                                readOnly
                                resize="none"
                                width="100%"
                                css={{ whiteSpace: 'pre-wrap' }}
                                />
                            ) : (
                                <Text
                                width="100%"
                                css={{ whiteSpace: 'pre-wrap' }}
                                >
                                {msg.query.content}
                                </Text>
                            )}
                            </Flex>
                        </Td>
                        <Td width="50%">
                            <Flex
                            align="center"
                            direction="column"
                            onClick={() => setSelectedMessage(msg.response.content)}
                            cursor="pointer"
                            >
                            {msg.response.content.length > 100 ? (
                                <Textarea
                                value={msg.response.content}
                                readOnly
                                resize="none"
                                width="100%"
                                css={{ whiteSpace: 'pre-wrap' }}
                                />
                            ) : (
                                <Text
                                width="100%"
                                css={{ whiteSpace: 'pre-wrap' }}
                                >
                                {msg.response.content}
                                </Text>
                            )}
                            </Flex>
                        </Td>
                        <Td width="10%">{new Date(msg.query.timestamp).toLocaleString()}</Td>
                        </Tr>
                    );
                    })}
                </Tbody>
                </Table>

                </TableContainer>

                <Flex height={2} mt="4" align="center" justify="flex-end">
                  <Button
                    onClick={() => setPageIndex((old) => Math.max(old - 1, 0))}
                    disabled={pageIndex === 0}
                    leftIcon={<FiChevronLeft />}
                    variant={'ghost'}
                  >
                  </Button>
                  <Button
                    onClick={() => setPageIndex((old) => Math.min(old + 1, Math.ceil(sortedMessages.length / pageSize) - 1))}
                    disabled={pageIndex >= Math.ceil(sortedMessages.length / pageSize) - 1}
                    rightIcon={<FiChevronRight />}
                    variant={'ghost'}
                  >
                    
                  </Button>
                </Flex>
              </Box>
            </>
        </>
      )}



{selectedMessage && (
  <Modal isOpen={!!selectedMessage} onClose={() => setSelectedMessage(null)} isCentered>
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(2px)" />
    <ModalContent maxWidth="600px" maxHeight="500px">
      <ModalCloseButton onClick={() => setSelectedMessage(null)} />
      <Box p="6" borderRadius="md" overflowY="auto" maxHeight="400px">
        <Text fontSize="lg" fontWeight="bold" mb="4">
          Message 
        </Text>
        <Text css={{ whiteSpace: 'pre-wrap' }}>
          {selectedMessage}
        </Text>
      </Box>
    </ModalContent>
  </Modal>
)}


    </Box>
  );
};

export default PrivateGPTMessages;
